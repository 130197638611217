/* eslint-disable no-new */
import {
    getUserInfo,
    queryMenu,
    userLoginout,
    getMessaeCount,
    getTaskType,
    userInfoValite,
    getAuthStep
} from '@/api';
import {
    SET_USERINFO,
    GET_APPLY_MENU,
    SET_MESSAGE_COUNT,
    SET_TASK_TYPE,
    SAVE_HOSP_DATA,
    USER_INFO_VALITE
} from './mutation-type';


// 获取auth状态。
const fetchAuthStep = ({ commit }) => (
    new Promise((resolve, reject) => {
        getAuthStep().then(res => {
            if (res && res.data > 1) {
                commit('SET_AUTH_STEP', 3);
            }
            resolve(res);
        }).catch(e => reject(e));
    })
);
// 获取用户信息
const fetchUserInfo = ({ commit }) => (
    new Promise((resolve, reject) => {
        getUserInfo().then(res => {
            if (res) {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                commit(SET_USERINFO, res.data);
            } else {
                commit(SET_USERINFO, {});
            }
            resolve(res);
        }).catch(e => reject(e));
    })
);

// 判断用户信息是否需要维护
const fetchUserInfoValite = ({ commit }) => (
    new Promise((resolve, reject) => {
        userInfoValite().then(res => {
            if (res.code === 0) {
                const result = {
                    msg: res.msg,
                    code: res.data
                };
                sessionStorage.setItem('userInfoValite', JSON.stringify(result));
                commit(USER_INFO_VALITE, result);
                resolve(res);
            }
        }).catch(e => {
            reject(e);
        });
    })
);

// 退出
const fetchLoginOut = ({ commit }) => (
    new Promise((resolve, reject) => {
        userLoginout().then(res => {
            commit(SET_USERINFO, {});
            localStorage.setItem('token', ''); // 清除token
            resolve(res);
        }).catch(e => {
            commit(SET_USERINFO, {});
            localStorage.setItem('token', ''); // 清除token
            reject(e);
        });
    })
);

// 获取项目外菜单
const fetchApplyMenu = ({ state, commit }) => (
    new Promise((resolve, reject) => {
        queryMenu().then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0 && !state.applyMeunList.length) {
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取用户消息数量
const fetchUserMessageCount = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getMessaeCount(params).then(res => {
            if (res.code !== 0) {
                commit(SET_MESSAGE_COUNT, 0);
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(SET_MESSAGE_COUNT, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

const fetchTaskType = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getTaskType(params).then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(SET_TASK_TYPE, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

const saveHospData = ({ commit }, params) => {
    commit(SAVE_HOSP_DATA, params);
};


export default {
    fetchUserInfo,
    fetchApplyMenu,
    fetchLoginOut,
    fetchUserMessageCount,
    fetchTaskType,
    saveHospData,
    fetchUserInfoValite,
    fetchAuthStep
};
