var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.hasHeader ? "page" : "" },
    [
      _vm.hasHeader ? _c("header-login") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("section", { staticClass: "top" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "type" }, [
              _vm._v(
                "\n                类型：" +
                  _vm._s(_vm.type.noticeSettingName) +
                  " 发布时间：" +
                  _vm._s(_vm.type.publishTime) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("section", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
          _vm._v(" "),
          _vm.fileList
            ? [
                _c("bk-upload", {
                  staticClass: "fileList",
                  model: {
                    value: _vm.fileList,
                    callback: function ($$v) {
                      _vm.fileList = $$v
                    },
                    expression: "fileList",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasHeader
        ? _c("portal-footer", { staticClass: "xq-footer" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }