/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:24:20
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-12-11 17:23:34
 * @Description: 路由集成页
 * @Route: Route
 */

import DefaultLayout from '@/components/layout/default';
import Iframe from '@/components/iframe'; // iframe容器组件
import fetch from '@/utils/fetch';
import { storageImg } from '@/utils/utils';
import Purview from './purview'; // 权限管理
import Health from './health';
import DataPlat from './data-plat';

const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/pages/not-found');
const Login = () => import(/* webpackChunkName: "not-found" */ '@/pages/login');
const Notice = () => import(/* webpackChunkName: "notice" */ '@/pages/notice');
const NoticeInfo = () => import(/* webpackChunkName: "noticeInfo" */ '@/pages/notice-info');
const Portal = () => import(/* webpackChunkName: "Portal" */ '@/pages/purview/portal');

const RestPassword = () => import(/* webpackChunkName: "RestPassword" */ '@/pages/purview/reset-password');
const OrgVertify = () => import(/* webpackChunkName: "OrgVertify" */ '@/pages/purview/reset-password/vertify');
const Password = () => import(/* webpackChunkName: "OrgVertify" */ '@/pages/purview/reset-password/password');
const RestSuccess = () => import(/* webpackChunkName: "RestSuccess" */ '@/pages/purview/reset-password/reset-success');


const nextRoute = (to, from, next) => {
    const token = localStorage.getItem('token');
    // 设置指定登录
    const domainInfo = JSON.parse(sessionStorage.getItem('domainInfo'));
    const loginPath = domainInfo && (domainInfo.loginPath || '/login');
    const isFrom = '';
    if (token) {
        next({
            path: `/portal${isFrom}`
        });
    } else if (loginPath !== to.path) {
        next({
            path: loginPath + isFrom
            // query: {
            //     path: to.fullPath
            // }
        });
    } else {
        next();
    }
};
const setIconId = domainInfo => {
    document.title = domainInfo.name;
    storageImg(domainInfo.iconId, {
        name: 'iconId',
        onBefore: r => {
            let dom = document.querySelector('link[rel=\'shortcut icon\']');
            if (!dom) {
                dom = document.createElement('link');
                dom.setAttribute('rel', 'shortcut icon');
                dom.setAttribute('type', 'image/x-icon');
                document.getElementsByTagName('head')[0].appendChild(dom);
            }
            dom.setAttribute('href', r);
        }
    });
};
/** *
 * 获得网站信息
 * 成功代表有数据
 * 失败则无数据
 */
const getDomainInfo = () => new Promise((success, error) => {
    let domainInfo = sessionStorage.getItem('domainInfo');
    // 请求
    fetch({
        url: '/upms/sysTenant/getUrl',
        method: 'get',
        handleMsg: true
    }).then(res => {
        if (res && res.code === 0) {
            if (window.App) {
                window.App.$store.commit('SET_DOMMIAN_INFO', res.data);
            } else {
                console.warn('⚠️：未找到window.App');
                setTimeout(() => {
                    window.App.$store.commit('SET_DOMMIAN_INFO', res.data);
                }, 4000);
            }
            setIconId(res.data);
            success();
        } else {
            window.App.$alert(res.msg);
            // error();
        }
    }, () => {
        error();
    });
    if (domainInfo) {
        domainInfo = JSON.parse(domainInfo);
        setIconId(domainInfo);
        success();
    }
});
// 请求url
getDomainInfo();

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        beforeEnter: (to, from, next) => {
            // ...
            getDomainInfo().then(() => {
                next();
            }, () => {
                next();
            });
        },
        redirect: '/portal',
        children: [
            ...Purview,
            ...DataPlat,
            {
                path: 'report-manage/*',
                name: 'Iframe',
                component: Iframe,
                meta: {
                    display: 'none',
                    title: '外链'
                }
            }
        ]
    },
    ...Health,
    {
        path: '/portal',
        component: Portal,
        beforeEnter: (to, from, next) => {
            // ...
            getDomainInfo().then(() => {
                next();
            }, () => {
                next();
            });
        },
        meta: {
            title: 'portal',
            noTag: true
        }
    },
    {
        path: '/notice',
        component: Notice,
        meta: {
            title: '消息中心',
            noTag: true
        }
    },
    {
        path: '/notice/info',
        component: NoticeInfo,
        meta: {
            title: '消息详情',
            noTag: true
        }
    },
    {
        path: '/login',
        component: Login,
        meta: {
            title: '登录',
            noTag: true
        },
        beforeEnter: (to, from, next) => {
            // ...
            getDomainInfo().then(() => {
                nextRoute(to, from, next);
            }, () => {
                next();
            });
        }
    },
    {
        path: '/reset',
        component: RestPassword,
        meta: {
            title: '重置密码'
        },
        children: [
            {
                path: 'vertify',
                component: OrgVertify,
                meta: {
                    title: '重置密码',
                    noTag: true
                }
            },
            {
                path: 'password',
                component: Password
            },
            {
                path: 'success',
                component: RestSuccess
            }
        ]
    },
    {
        path: '*',
        component: DefaultLayout,
        children: [
            {
                path: '404',
                component: NotFound,
                meta: {
                    title: '404',
                    noTag: true
                }
            },
            {
                path: '*',
                component: NotFound,
                meta: {
                    title: '404',
                    noTag: true
                }
            }
        ]
    }
];

export default routes;
