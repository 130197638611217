/*
 * @Author: zhuangmw
 * @Email: zhuangmw@hxmec.com
 * @Date: 2020-03-19 14:10:44
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-03-24 10:46:09
 * @Description: 大健康交易平台
 */
import View from '@/components/view';

const healthLogin = () => import(/* webpackChunkName: "healthLogin" */ '@/pages/health/login');
const Layout = () => import(/* webpackChunkName: "layout" */ '@/pages/health/layout');
const Detail = () => import(/* webpackChunkName: "Detail" */ '@/pages/health/review/detail');

export default [
    {
        path: '/health',
        component: View,
        meta: {
            title: '大健康交易平台',
            noTag: true
        },
        children: [
            {
                path: 'login',
                component: healthLogin,
                meta: {
                    title: '登录',
                    noTag: true
                }
            },
            {
                path: 'review',
                component: Layout,
                meta: {
                    title: '审核',
                    noTag: true
                },
                children: [
                    {
                        path: 'detail',
                        component: Detail,
                        meta: {
                            title: '待审核资料',
                            noTag: true
                        }
                    }
                ]
            },
            // 注册路由
            {
                path: 'register',
                component: () => import('@/pages/health/register/index.vue'),
                redirect: 'register/base-info',
                children: [
                    {
                        path: 'base-info',
                        component: () => import('@/pages/health/register/base-info.vue'),
                        meta: {
                            title: '基础信息'
                        }
                    },
                    {
                        path: 'org-info',
                        component: () => import('@/pages/health/register/org-info.vue'),
                        meta: {
                            title: '机构信息'
                        }
                    },
                    {
                        path: 'submit-audit',
                        component: () => import('@/pages/health/register/submit-audit.vue'),
                        meta: {
                            title: '提交审核'
                        }
                    }
                ]
            }
        ]
    }
];
