/* eslint-disable */
export default class Queue {
    constructor() {
        this.top = 0, // 栈的长度
        this.list = [];
    }

    push(item) {
        this.top += 1;
        this.list.push(item); // 入栈操作
    }

    shift() {
        this.top -= 1;
        return this.list.shift(); // 出栈操作
    }

    peek() {
        return this.list[this.top - 1]; // 查询栈顶元素
    }
};
