/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2019-07-05 16:51:39
 * @Last Modified by: Danielz
 * @Last Modified time: 2023-05-19 10:23:53
 * @Description: 公共请求封装
 */
import Axios from 'axios';
import Configs from '@/config';

/*
 * 判断对象是否为空 避免依赖循环
 */
export const isEmptyObj = obj => !obj || Object.keys(obj).length === 0;

/**
 * 跳转到来源网站
 * @param {*} path 一般情况下为token=null，用于清空token
 * @param {*} router
 * @param {*} appCenter 前往外边/portal
 */
export const gotofromHost = (path, router, appCenter) => {
    const fromHost = localStorage.getItem('fromHost');
    const isOut = path.indexOf('token=null') > -1; // 退出
    // 如果appCenter执行回应用中心如:portal跳到医药云
    if (fromHost || appCenter) {
        // 返回外部应用中心
        let url = fromHost;
        // 前往外边的应用中心
        if (!isOut && appCenter) {
            // 部分旧系统使用的为“1”
            url = appCenter === '1' ? `${url}/portal` : appCenter;
        }
        if (isOut && fromHost && fromHost.indexOf('workbench') !== -1) {
            // 获取域名 
            const getDomain = urlString => {
                // 如果 URL 不带协议，默认加上 https://
                const fullUrl = urlString.includes('://') ? urlString : `https://${urlString}`;
                const newUrl = new URL(fullUrl); // 创建 URL 对象
                return newUrl.port ? `${newUrl.hostname}:${newUrl.port}` : newUrl.hostname; // 获取域名部分
            };
            // 获取协议
            const protocolType = window.location.protocol;
            // 调用获取域名的方法
            const domain = getDomain(url);
            const protocol = protocolType.replace(':', ''); // 去掉结尾的冒号
            // eslint-disable-next-line prefer-destructuring
            url = url.replace('workbench', `auth/index.html?from=${protocol}%3A%2F%2F${domain}%2Fpublic%2Fserver-adapter.html%3Ftarget%3D%2Fworkbench`);
            console.log('url走的是外联', url);
        }
        // 避免没有加协议的情况
        if (url.indexOf('//') === -1) {
            // 兼容参数缺少//
            url = `//${url}`;
        }
        window.location.href = url + (isOut ? path : '');
    } else {
        router.$router.push(path);
    }
};
/**
 * 去除参数前后空格 避免依赖循环
 * @param { Object } paramsWrapper 参数对象
 */
export const removeBlank = (paramsWrapper = {}) => {
    if (paramsWrapper.constructor === Object && !isEmptyObj(paramsWrapper)) {
        Object.keys(paramsWrapper).forEach(key => {
            const val = paramsWrapper[key];
            if (typeof val === 'string') {
                paramsWrapper[key] = val.trim();
            }
            if (val && val.constructor === Object) {
                removeBlank(val);
            }
        });
    }
    return paramsWrapper;
};

const fetch = async config => {
    const { App } = window;
    const {
        handleMsg,
        method,
        params,
        independent,
        timeout,
        baseUrl,
        ...other
    } = config;
    let { url } = config;

    const jwtData = localStorage.getItem('token'); // 获取token
    // const itemId = getUrlParame('itemId');
    let authorization = '';
    // 未登录
    const authorizations = [
        '/upms/login/phone',
        '/auth/oauth/token',
        '/upms/user/caCheckUser',
        '/auth/mobile/token/social',
        '/user/register'
    ];

    if (authorizations.includes(url)) {
        authorization = 'Basic aHhtZWM6aHhtZWM=';
    } else if (jwtData) {
        authorization = `Bearer ${jwtData}`;
    }
    const headers = {
        from: 'Y'
        // isToken: false
        // 'Content-Type': 'application/x-www-form-urlencoded'
    };

    if (authorization) {
        headers.Authorization = authorization; // jwtData || ''
    }

    const timestamp = new Date().getTime();
    // 转换服务器
    let baseURL = baseUrl || Configs.baseUrl;
    const Osses = Configs.baseOss;
    if (Osses) {
        Object.keys(Osses).forEach(key => {
            const reg = new RegExp(`^${key}`);
            if (url.match(reg)) {
                url = url.replace(reg, '');
                baseURL = Osses[key];
            }
        });
    }
    // 创建axios实例
    const axiosIns = Axios.create();
    // 获取登录页面的配置信息不能带token,base服务不需要token
    if (url.indexOf('/sysTenant/getUrl') > -1 || url.indexOf('/base/fe') >= 0) {
        delete headers.Authorization;
        // delete headers.from;
    }
    
    let options = {
        headers,
        baseURL,
        url,
        ...other,
        timeout: timeout || 10 * 60 * 1000
    };
    // 增加请求参数
    const paramObj = removeBlank(params);

    if (method === 'post') {
        if (url.match(/^\/auth/) !== null) {
            options = Object.assign({}, options, {
                method: 'post',
                params: paramObj
            });
        } else {
            options = Object.assign({}, options, {
                method: 'post',
                data: paramObj
            });
        }
    } else if (method === 'put') {
        options = Object.assign({}, options, { method: 'put', data: paramObj });
    } else if (method === 'delete') {
        options = Object.assign({}, options, {
            method: 'delete',
            data: paramObj
        });
    } else {
        options = Object.assign({}, options, {
            method: 'get',
            params: {
                ...paramObj,
                timestamp
            }
        });
    }
    // 处理特殊[]符号
    // if (options.method === 'get') {
    //     // let urlStr = '';
    //     const param = JSON.stringify(options.params);
    //     // param.replace(/\[|\]/g,'')
    //     // Object.keys(param).forEach(key => {
    //     //     const val = param[key];
    //     //     if (urlStr) urlStr += '&';
    //     //     urlStr += `${key}=${val ? encodeURI(val) : ''}`;
    //     // });
    //     // options.url += `?${urlStr}`;
    //     options.params = JSON.parse(param.replace(/(\[|\])*/g, ''));
    // }

    const response = await axiosIns(options)
        .then(res => {
            const { code, msg, message } = res.data;
            if (code === '99') {
                return 99;
            }
            // independent 存在做单独异常处理，其余走统一业务处理
            if (independent) return res.data;
            if (code !== 0 && code !== '0' && !handleMsg) {
                // code不为0视为错误,也可以设置 handleMsg自己处理
                App.$message({
                    type: 'error',
                    message: msg || message,
                    showClose: true
                });
                return false;
            }
            // 用来标识是否弹出《请重新登录》的弹出框。
            if (sessionStorage.getItem('isFirstIn') === null) {
                sessionStorage.setItem('isFirstIn', 'no');
            }
            return res.data;
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                if (sessionStorage.getItem('isFirstIn') === 'no') {
                    App.$message.error(err.response.data.msg || '请重新登录');
                }
                App.$store.commit('CLEAR_USERINFO');
                localStorage.setItem('token', '');
                // 处理外链型系统
                gotofromHost('?token=null', App);
                return false;
            }
            if (err.response && err.response.status === 426) {
                App.$message({
                    type: 'error',
                    message: err.response.data.msg,
                    showClose: true
                });
                return false;
            }
            if (err.response && err.response.status === 429) {
                App.$message.error('当前请求过多，请稍后重试');
                return false;
            }
            if (err.response && err.response.status === 404) {
                App.$message.error(`未找到对应接口:${url}`);
                return false;
            }
            // 错误内容提示
            const errData = err.response ? err.response.data : '';
            const env = process.env.NODE_ENV;
            // 非开发环境提示 //(-100, "业务异常")(-200, "系统异常")
            if (env !== 'development' && errData && errData.code === -2) {
                // 超时
                App.$message.error(`接口超时:${url}`);
                return false;
            }
            if (
                env !== 'development' && errData && (errData.code === -200 || errData.code === -1)
            ) {
                // 系统繁忙
                App.$message.error(`服务异常:${url}`);
                return false;
            }
            // 错误内容提示
            if (errData && typeof errData === 'string') {
                App.$message({
                    type: 'error',
                    message: errData,
                    showClose: true
                });
            } else if (errData && typeof errData === 'object' && errData.msg) {
                App.$message({
                    type: 'error',
                    message: errData.msg,
                    showClose: true
                });
            } else {
                App.$message.error(`系统异常:${url}`);
            }

            return false;
        });

    return response;
};

export default fetch;
