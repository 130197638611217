/**
 * @author xiaoyu
 * @email wangxy2@guahao.com
 * @date 2019/7/11
 * @lastModifiedBy 11094
 * @lastModifiedTime 18:58
 * @route
 * @description: iframe组件，用于渲染所有带schema的外部网页
*/
<template>
    <iframe id="framework"
            title="framework"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            :src="outlink" />
</template>

<script>
export default {
    data() {
        return {
            outlink: '' // 外部链接
        };
    },
    created() {
        this.outlink = `${this.$bkConfig.wedataOrigin}${this.$route.fullPath}`;
        window.addEventListener('message', this.receiveMessage);
    },
    methods: {
        receiveMessage(event) {
            const { data = '{}', origin } = event;
            if (origin !== this.$bkConfig.wedataOrigin || typeof data !== 'string') return;
            const dataJson = JSON.parse(data);
            const { type } = dataJson;
            
            switch (type) {
            case 'tokenErr':
                this.$alert('当前登录状态已失效', '友情提醒', {
                    confirmButtonText: '去登录',
                    callback: () => {
                        this.$router.push('/login');
                    }
                });
                break;
            default:
                break;
            }
        }
    }
};
</script>