/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2021-03-02 11:14:52
 * @Last Modified by: qiuwt
 * @Last Modified time: 2021-03-09 11:04:22
 * @Description: 用户状态
 */

const backPath = localStorage.getItem('btnBackPath');
const btnBackPath = backPath ? JSON.parse(backPath) : {};
export default {
    userAppNum: 2, // 数据应用数
    userInfo: {},
    applyMeunList: [],
    message: 0,
    taskType: [],
    hospData: {}, // 监控中心医院数据存储
    projectName: '', // 项目名称
    innerProName: localStorage.getItem('innerProName'), // 项目内名称（招采）
    webName: '', // 网站名称内容页左上角
    routeData: [], // 微前端路由信息
    index: '', // 默认首页
    btnBackPath, // 跳转项目外地址
    projectApi: {
        // demo:{
        //    main:'', //工作台
        //    outer:'',// 项目外
        //    inner:'',// 项目内
        // }
    },
    userInfoValite: {
        msg: '',
        code: ''
    }, // 用户数据是否应该维护
    domainInfo: {},
    authStep: 0 // 授权数据的步骤，1，2，3
};
