/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:51:54
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2020-04-02 14:11:25
 * @Description: 统一的正则表达式
 */

/*
 * @description 登录账号
 */
export const loginReg = /^[a-zA-Z0-9~!@#$%&^*\\/.\-_?]{1,20}$/;
/*
 * @description 密码正则
 */
export const passwordReg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.\\/\-_?])(.{8,20})$/;
/*
 * @description 手机正则
 */
export const mobileReg = /^1[0-9]{10}$/;

/*
 * @description 正整数 包括0
 */
export const naturalNumberReg = /^[+]{0,1}(\d+)$/;

/*
 * @description 任意正整数，正小数，包含0
 */
export const intPointReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;

/*
 * @description 正浮点数
 */
export const intNumberPointReg = /^([1-9]+(\.\d+)?|0\.\d+)$/;

/*
 * @description 价格正则
 */
export const priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;

/*
 * @description 邮箱正则
 */
export const mallReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;

/**
 * @description 身份证正则
 */
export const IDcardReg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 

/**
 * @description 统一社会信用代码
 */
export const tyshyReg = /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}/g;
