import {
    SET_USERINFO,
    CLEAR_USERINFO,
    GET_APPLY_MENU,
    SET_MESSAGE_COUNT,
    SET_MESSAGE_COUNT_DOWN,
    SET_TASK_TYPE,
    SAVE_HOSP_DATA,
    SET_WEBNAME,
    SET_PROJECT,
    SAVE_ROUTE_DATA,
    SAVE_PROJECT_INFO,
    USER_INFO_VALITE
} from './mutation-type';

export const getArrayTarget = array => {
    let target = '';
    for (let i = 0; i < array.length; i += 1) {
        const item = array[i];
        if (item.parentId !== '-999' && item.sort === 0) {
            target = item;
            break;
        } else if (item.children) {
            target = getArrayTarget(item.children);
            if (target) {
                break;
            }
        }
    }
    return target;
};

export default {
    // 设置授权步骤
    SET_AUTH_STEP(state, data) {
        if (data === 3 && state.authStep === 2) {
            // 只有在步骤2的时候如果最后一次的授权为完成，才可以进入第三步；
            state.authStep = data;
        } else {
            state.authStep = data;
        }
    },
    // 网站名称
    [SET_WEBNAME](state, data) {
        state.webName = data;
    },
    // 项目模块
    [SET_PROJECT](state, data) {
        state.projectName = data;
    },
    /** *
     * 项目内名称（招采）
     */
    SET_INNER_PRO_NAME(state, data) {
        state.innerProName = data;
        localStorage.setItem('innerProName', data);
    },
    CLEAN_INNER_PRO_NAME(state) {
        state.innerProName = '';
        localStorage.removeItem('innerProName');
    },
    // 用户消息数量
    [SET_MESSAGE_COUNT](state, data) {
        state.message = data;
    },
    // 读取消息每次减1；
    [SET_MESSAGE_COUNT_DOWN](state) {
        if (state.message > 0) {
            state.message -= 1;
        } else {
            state.message = 0;
        }
    },
    [SET_USERINFO](state, data) {
        state.userInfo = data;
    },
    [CLEAR_USERINFO](state) {
        // 退出
        localStorage.removeItem('userType');
        localStorage.removeItem('system_id');
        state.userInfo = null;
    },
    /**
     * 设置回退到项目外地址
     * @param {String} data 地址
     */
    SET_BACK_OUTER(state, data) {
        state.btnBackPath = data || {};
        // 避免刷新页面后丢失
        localStorage.setItem('btnBackPath', JSON.stringify(data));
    },
    /**
     * 获得应用数，当为1时不显示前往portal
     * @param {*} state 
     * @param {*} data 
     */
    SET_APP_NUM(state, data) {
        state.userAppNum = data;
    },
    /**
     * 修改网站信息
     * @param {*} state 
     * @param {*} data 
     */
    SET_DOMMIAN_INFO(state, data) {
        data.copyright = 'Copyright © 2022 - 2024 山东互联网中药材交易中心有限公司 版权所有 鲁ICP备2021033292号-2';
        if (data) sessionStorage.setItem('domainInfo', JSON.stringify(data));
        state.domainInfo = data || {};
    },
    // 获取应用菜单
    [GET_APPLY_MENU](state, data) {
        // portal页面保存的userType;
        let userTp = localStorage.getItem('userType');
        const id = localStorage.getItem('system_id') || localStorage.getItem('systemId');
        const result = [];
        let pathname = '';
        // 获得当前路由别名
        if (!id && !userTp) {
            pathname = window.location.pathname.split('/');
            [, userTp] = pathname;
        }
        // 获得对应菜单
        for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            let ishas = false;
            // 兼容/report 和 report、report?gCode=** 等情况 
            let cPaths = item.path ? item.path.split(/[/|?|&]/) : [];
            // 去除空对象，获得路由别名
            cPaths = cPaths.filter(v => v !== '');
            let cPath = item.path;
            if (cPaths.length) {
                [cPath] = cPaths;
            }
            if (id === item.systemId) {
                // 应用id
                ishas = true;
            } else if (!id && cPath === userTp) {
                // 别名
                if (item.menuCode && window.location.href.indexOf(item.menuCode) > -1) {
                    ishas = true;
                } 
                if (!item.menuCode) {
                    ishas = true;
                }
            }
            if (ishas) {
                // 设置左上角的应用名称
                state.webName = item.name;
                result.push(item);
                break;
            }
        }
        if (result.length) {
            // 获得首页路由
            const index = getArrayTarget(result);
            if (index) state.index = index.path;
        }
        state.applyMeunList = result;
    },
    /**
     * 清除菜单
     * @param {*} state 
     */
    ClEAN_APPLY_MENU(state) {
        state.applyMeunList = [];
        state.index = '';
    },
    [SET_TASK_TYPE](state, data) {
        state.taskType = data;
    },
    // 保存医院数据
    [SAVE_HOSP_DATA](state, data) {
        // console.log(data);
        state.hospData[data.key] = data.data;
    },
    // 微前端保存实际路由
    [SAVE_ROUTE_DATA]({ routeData }, data) {
        // 获得路由的第一个路径
        const [current] = data;
        const isHas = routeData.find(item => item.path === current.path);
        if (!isHas) {
            data.forEach(item => {
                routeData.push(item);
            });
        }
    },
    // 微前端项目内外请求
    [SAVE_PROJECT_INFO](store, data = {}) {
        const { projectApi } = store;
        const { name, params } = data;
        projectApi[name] = params;
        store.projectApi = JSON.parse(JSON.stringify(projectApi));
    },
    [USER_INFO_VALITE](state, data = {}) {
        if (data !== null) {
            state.userInfoValite = data;
        }
    }
};
