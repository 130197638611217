/* eslint-disable max-len */
/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-08 09:47:23
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-07 13:51:s18
 * @Description: 全局版头部
 */
<template>
    <div class="hx-header">
        <section class="head-content">
            <span class="title">
                <router-link to="/">
                    {{ domainInfo.name }}
                </router-link>
            </span>
            <span v-if="domainInfo.hotLine"
                  class="hot-lint">
                <i class="hxaxure">&#xe696;</i> {{ domainInfo.hotLine }}
            </span>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['domainInfo'])
    }
};
</script>

<style lang="less" scoped>
.hx-header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: @--color-primary;
    color: @--color-white;
    margin-bottom: 24px;
    .head-content {
        display: flex;
        justify-content: space-between;
        width: 1152px;
        margin: 0 auto;
    }
    .title {
        font-size: 20px;
        /deep/ a {
            color: @--color-white;
            text-decoration: none;
        }
    }
    .hot-lint {
        font-size: 16px;
        font-weight: 900;
        text-align: left;
    }
}
</style>
