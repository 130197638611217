export default {
    baseUrl: 'https://apitcme.guahao-test.com/code', // 运维中心数据服务
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 上传
    uploadPath: 'https://apitcme.guahao-test.com/code/base/fe/upload', // 上传
    vcodePath: 'https://apitcme.guahao-test.com/code/code',
    baseOss: {
        'https://apitcme.guahao-test.com/code': ''
    }
};
