var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "med-container", class: { mgr: _vm.visualFlag } },
    [
      _c("div", { ref: "container", staticClass: "navbar" }),
      _vm._v(" "),
      _c("common-header", {
        ref: "header",
        on: { changeMenu: _vm.changeMenu, route: _vm.getBreadcrumbData },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "med-content" }, [
        _c(
          "div",
          { staticClass: "left-wrapper" },
          [
            _c(
              "el-menu",
              {
                class: ["med-menu", _vm.isOutsideProject ? "no-back-btn" : ""],
                style: { bottom: "40px" },
                attrs: {
                  "default-active": _vm.routerIndex,
                  "background-color": "#0b56a3",
                  "text-color": "#fff",
                  "active-text-color": "#fff",
                  collapse: !_vm.sideShow,
                  "default-openeds": _vm.openeds,
                },
                on: { select: _vm.handleSelect },
              },
              [
                _c("SidebarMenu", {
                  attrs: {
                    "menu-data": _vm.menuDataFilter,
                    "default-active": _vm.routerIndex,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.sideShow
              ? _c("div", { staticClass: "back-btn back-side1-btn" }, [
                  _vm.userAppNum > 1 || _vm.hasfromHost
                    ? _c(
                        "a",
                        {
                          staticClass: "back-btn_1",
                          on: { click: _vm.gotoPortal },
                        },
                        [
                          _vm._v(
                            "\n                    前往应用中心\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("a", { on: { click: _vm.handleSide } }, [
                    _c("i", { staticClass: "el-icon-d-arrow-left" }),
                    _vm._v("\n                    收起\n                "),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "back-btn back-side2-btn",
                on: { click: _vm.handleSide },
              },
              [
                _c("span", { staticClass: "back-side2-span" }, [
                  _vm._v("\n                    展开\n                "),
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-d-arrow-right" }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-wrapper" },
          [
            _vm.breadcrumbData && _vm.breadcrumbData.length > 1
              ? _c(
                  "el-breadcrumb",
                  { staticClass: "med-breadcrumb" },
                  [
                    _vm.backUrlData
                      ? [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn-back fr",
                              attrs: { to: _vm.backUrlData },
                            },
                            [
                              _vm._v(
                                "\n                        返回\n                    "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "breadcrumb-desc" }, [
                      _vm._v("当前位置："),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.breadcrumbData, function (item, index) {
                      return [
                        _vm.breadcrumbData.length === index + 1
                          ? _c("el-breadcrumb-item", { key: index }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.label) +
                                  "\n                    "
                              ),
                            ])
                          : _c(
                              "el-breadcrumb-item",
                              {
                                key: index,
                                attrs: {
                                  to: { path: item.fullPath || item.path },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.label) +
                                    "\n                    "
                                ),
                              ]
                            ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  { "has-breadcrumb": _vm.breadcrumbData.length > 0 },
                  "right-content",
                ],
              },
              [
                _c(
                  "keep-alive",
                  [
                    _vm.$route.path === "/message"
                      ? _c("router-view")
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.$route.path !== "/message" ? _c("router-view") : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }