export default {
    userInfo: state => {
        const userInfoStorage = localStorage.getItem('userInfo');
        const { userInfo } = state;
        if (JSON.stringify(userInfo) === '{}') {
            if (userInfoStorage !== '{}' && userInfoStorage !== null) {
                return JSON.parse(userInfoStorage);
            }
            return {};
        }
        return userInfo || {};
    },
    userAppNum: state => state.userAppNum,
    applyMeunList: state => state.applyMeunList,
    message: state => state.message,
    taskType: state => state.taskType,
    webName: state => state.webName,
    projectName: state => state.projectName,
    projectApi: state => state.projectApi,
    index: state => state.index,
    userInfoValite: state => {
        const valite = sessionStorage.getItem('userInfoValite');
        if (state.userInfoValite.code === '' && valite !== null) { // 刷新导致数据丢失
            return JSON.parse(valite);
        }
        return state.userInfoValite;
    },
    /**
     * 网站信息
     */
    domainInfo: state => {
        const domainInfo = sessionStorage.getItem('domainInfo');
        // 默认
        const template = {
            hotLine: '', // 服务热线
            productionUnit: '药械联合采购中心', // 承建单位
            managementUnit: '医疗保障管理委员会办公室', // 管理单位
            name: '海西药械集中采购平台',
            logoScript: '',
            technicalSupport: '厦门海西医药交易中心有限公司',
            copyright:
                'Copyright © 2022 - 2024 山东互联网中药材交易中心有限公司 版权所有 鲁ICP备2021033292号-2',
            copyrightUrl: 'http://beian.miit.gov.cn'
        };
        const current = state.domainInfo.backImg ? state.domainInfo : (domainInfo && JSON.parse(domainInfo)) || template;
        
        return current;
    },
    authStep: state => state.authStep
};
