var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hx-header" }, [
    _c("section", { staticClass: "head-content" }, [
      _c(
        "span",
        { staticClass: "title" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.domainInfo.name) +
                "\n            "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.domainInfo.hotLine
        ? _c("span", { staticClass: "hot-lint" }, [
            _c("i", { staticClass: "hxaxure" }, [_vm._v("")]),
            _vm._v(" " + _vm._s(_vm.domainInfo.hotLine) + "\n        "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }