/*
 * @author 张志成
 * @description mutation常量管理
 */
// 网站名称
export const SET_WEBNAME = 'SET_WEBNAME';
// 项目名称
export const SET_PROJECT = 'SET_PROJECT';
// 用户信息
export const SET_USERINFO = 'SET_USERINFO';
// 清除用户信息
export const CLEAR_USERINFO = 'CLEAR_USERINFO';
// 设置菜单id
export const SET_MENUID = 'SET_MENUID';
// 获取应用菜单
export const GET_APPLY_MENU = 'GET_APPLY_MENU';
// 设置用户消息数量
export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT';
// 设置用户消息数量-1
export const SET_MESSAGE_COUNT_DOWN = 'SET_MESSAGE_COUNT_DOWN';

// 获取任务列表类型
export const SET_TASK_TYPE = 'SET_TASK_TYPE';

// 保存医院数据
export const SAVE_HOSP_DATA = 'SAVE_HOSP_DATA';

// 保存用户模块数量
export const SAVE_USER_MODULE_NUM = 'SAVE_USER_MODULE_NUM';

// 微前端保存路由信息
export const SAVE_ROUTE_DATA = 'SAVE_ROUTE_DATA';
// 微前端保存项目内请求信息
export const SAVE_PROJECT_INFO = 'SAVE_PROJECT_INFO';

export const USER_INFO_VALITE = 'USER_INFO_VALITE';
