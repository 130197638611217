var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menuData && _vm.menuData.length
    ? _c(
        "div",
        [
          _vm._l(_vm.menuData, function (item, i) {
            return [
              item.children && item.children.length > 0 && item.hasChildren
                ? _c(
                    "el-submenu",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.permission !== "hide" || item.sort !== 0,
                          expression:
                            "item.permission !== 'hide' || item.sort !== 0",
                        },
                      ],
                      key: item.id || item.path || i,
                      attrs: {
                        "data-id": item.id,
                        index: item.id || item.path,
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        item.icon
                          ? _c("i", {
                              staticClass: "hxaxure",
                              domProps: { innerHTML: _vm._s(item.icon) },
                            })
                          : _c("i", { staticClass: "hxaxure" }, [
                              _vm._v(
                                "\n                    \n                "
                              ),
                            ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("sidebar-menu", {
                        staticClass: "med-submenu",
                        attrs: {
                          "menu-data": item.children,
                          "default-active": _vm.defaultActive,
                        },
                      }),
                    ],
                    2
                  )
                : item.path && item.path.indexOf("/hxcm/screen/visual") !== -1
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id },
                      on: { click: () => _vm.handleToScreen(item) },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : item.path && item.path.indexOf("/mgr/visual/home") !== -1
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id },
                      on: { click: _vm.handleToHsaScreen },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : item.path && item.path.indexOf("/jlmgr/visual/home") !== -1
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id },
                      on: { click: _vm.handleToJlScreen },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : item.path &&
                  item.path.indexOf(
                    "/mgr/visual/province-enterssells-saves"
                  ) !== -1
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id },
                      on: { click: _vm.handleToSdScreen },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : item.path && item.path.indexOf("/hxupsc/visual") !== -1
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id },
                      on: {
                        click: function ($event) {
                          return _vm.handleToUpscScreen(item.path)
                        },
                      },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : item.type !== "1" &&
                  (item.permission !== "hide" || item.sort !== 0)
                ? _c(
                    "el-menu-item",
                    {
                      key: item.id || item.path || i,
                      attrs: { "data-id": item.id, index: item.path },
                    },
                    [
                      item.icon
                        ? _c("i", {
                            staticClass: "hxaxure",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          })
                        : _c("i", { staticClass: "hxaxure" }, [
                            _vm._v("\n                \n            "),
                          ]),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }