var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    attrs: {
      id: "framework",
      title: "framework",
      width: "100%",
      height: "100%",
      frameborder: "0",
      scrolling: "no",
      marginheight: "0",
      marginwidth: "0",
      src: _vm.outlink,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }