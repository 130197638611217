/*
 * @author 张志成
 * @description 递归渲染菜单
 * @create 2018-12-18
 */

<template>
    <div v-if="menuData && menuData.length">
        <template v-for="(item, i) in menuData">
            <el-submenu v-if="item.children && item.children.length > 0 && item.hasChildren"
                        v-show="item.permission !== 'hide' || item.sort !== 0"
                        :key="item.id ||item.path || i"
                        :data-id="item.id"
                        :index="item.id||item.path">
                <template slot="title">
                    <i v-if="item.icon"
                       class="hxaxure"
                       v-html="item.icon" />
                    <i v-else
                       class="hxaxure">
                        &#xe7a8;
                    </i>
                    <span slot="title">{{ item.name }}</span>
                </template>
                <sidebar-menu :menu-data="item.children"
                              :default-active="defaultActive"
                              class="med-submenu" />
            </el-submenu>
            <el-menu-item v-else-if="item.path && item.path.indexOf('/hxcm/screen/visual') !== -1"
                          :key="item.id || item.path || i"
                          :data-id="item.id"
                          @click="()=>handleToScreen(item)">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item v-else-if="item.path && item.path.indexOf('/mgr/visual/home') !== -1"
                          :key="item.id || item.path || i"
                          :data-id="item.id"
                          @click="handleToHsaScreen">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item v-else-if="item.path && item.path.indexOf('/jlmgr/visual/home') !== -1"
                          :key="item.id || item.path || i"
                          :data-id="item.id"
                          @click="handleToJlScreen">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item v-else-if="item.path && item.path.indexOf('/mgr/visual/province-enterssells-saves') !== -1"
                          :key="item.id || item.path || i"
                          :data-id="item.id"
                          @click="handleToSdScreen">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item v-else-if="item.path && item.path.indexOf('/hxupsc/visual') !== -1"
                          :key="item.id || item.path || i"
                          :data-id="item.id"
                          @click="handleToUpscScreen(item.path)">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
            <!-- 按钮类型、显示、是否工作台 -->
            <el-menu-item v-else-if="item.type !== '1'&& (item.permission !== 'hide' || item.sort !== 0)"
                          :key="item.id|| item.path || i"
                          :data-id="item.id"
                          :index="item.path">
                <!--path跳转-->
                <i v-if="item.icon"
                   class="hxaxure"
                   v-html="item.icon" />
                <i v-else
                   class="hxaxure">
                    &#xe791;
                </i>
                <span slot="title">{{ item.name }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SidebarMenu',
    props: {
        menuData: {
            type: Array,
            default: () => ({})
        },
        defaultActive: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleToScreen(item) {
            if (item.path)window.open(item.path);
        },
        // 吉林价格监管大屏
        handleToJlScreen() {
            window.open('/jlmgr/visual/home');
        },
        // 国家局监管大屏
        handleToHsaScreen() {
            window.open('/mgr/visual/home#1');
        },
        // 山东进销存大屏
        handleToSdScreen() {
            window.open('/mgr/visual/province-enterssells-saves?tenantIdStr=370000');
        },
        // 联采大屏
        handleToUpscScreen(path) {
            const { itemId } = this.$route.query;
            window.open(`${path}?itemId=${itemId}`);
        }
    }
};
</script>

<style lang="less" scoped>
.hxaxure {
    color: rgba(255, 255, 255, 0.6);
}
/deep/ .el-submenu .el-menu-item {
    height: 40px;
    line-height: 40px;
}
/deep/ .el-submenu__title:hover,
/deep/ .el-menu-item:hover,
/deep/ .el-menu-item.is-active {
    background-color: @--color-primary !important;
    color: @--color-white;
}
/deep/ .el-menu-item i {
    vertical-align: baseline;
    color: inherit;
    font-size: 16px;
}
</style>
