export default {
    // baseUrl: 'http://hxgwpre.heisea.cn/'
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 服务集合
    // baseOss: {
    //     '/base': 'http://hxbase.heisea.cn/'
    // },
    // 上传
    // filePath: 'http://hxbasepre.heisea.cn/info', // 文件信息
    // uploadPath: 'http://hxbasepre.heisea.cn/upload'

    baseUrl: '//pre-api.bqex.com/code', // 运维中心数据服务
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 上传
    uploadPath: '//pre-api.bqex.com/code/base/fe/upload', // 上传
    filePath: '//pre-api.bqex.com/code/base/fe/info', // 文件信息
    vcodePath: '//pre-api.bqex.com/code/code',
    baseOss: {
        '//pre-api.bqex.com/code': ''
    }
};
