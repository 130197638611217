var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "xq-footer" }),
    _vm._v(" "),
    _c("div", { staticClass: "xq-footer" }, [
      _vm.domainInfo.copyright
        ? _c("p", [
            _c(
              "a",
              {
                staticClass: "b_link",
                attrs: { href: _vm.domainInfo.copyrightUrl },
              },
              [_vm._v(_vm._s(_vm.domainInfo.copyright))]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }