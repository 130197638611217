
import Brick from '@heisea/brick';
import InIt from './main.base';


const vueLifecycles = InIt(Vue => Vue.use(Brick));
// const vueLifecycles = InIt();


// 项目启动的钩子
export const bootstrap = [
    vueLifecycles.bootstrap
];
// 项目启动后的钩子
export const mount = [
    vueLifecycles.mount
];
// 项目卸载的钩子
export const unmount = [
    vueLifecycles.unmount
];
