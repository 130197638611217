/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2021-06-18 15:15:15
 * @Last Modified by: qiuwt
 * @Last Modified time: 2021-06-24 11:10:37
 * @Description: 消息中心详情页
 * @Route: /notice/info
 */
<template>
    <div :class="hasHeader?'page':''">
        <header-login v-if="hasHeader" />
        <div class="section">
            <section class="top">
                <h1 class="title">
                    {{ title }}
                </h1>
                <p class="type">
                    类型：{{ type.noticeSettingName }} 发布时间：{{ type.publishTime }}
                </p>
            </section>
            <section class="content"
                     v-html="content" />
            <template v-if="fileList">
                <bk-upload v-model="fileList"
                           class="fileList" />
            </template>
        </div>
        <portal-footer v-if="hasHeader"
                       class="xq-footer" />
    </div>
</template>
<script>
import PortalFooter from '@/components/portal-footer';
import HeaderLogin from '../components/header-login';

export default {
    components: {
        HeaderLogin,
        PortalFooter
    },
    props: {
        hasHeader: {
            type: Boolean,
            default: true
        },
        params: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            fileList: '',
            title: '',
            content: '',
            type: {}
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            const params = this.params || (this.$route.query && JSON.parse(this.$route.query.params));
            this.$fetch({
                url: '/upms/msgNoticeInfo/read',
                params,
                method: 'post'
            }).then(res => {
                if (res) {
                    this.content = res.data.context;
                    this.title = res.data.title;
                    this.type = params;
                    if (res.data.accessoryFile) this.fileList = res.data.accessoryFile || '';
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.section {
    padding: 50px 90px;
    margin: 0 auto;
    background: @--color-white;
    .content {
        min-height: 200px;
    }
    .title {
        width: 600px;
        text-align: center;
        margin: 0 auto;
    }
    .type {
        text-align: center;
        padding: 16px;
        margin-bottom: 25px;
        color: #575c66;
        border-bottom: 2px solid #37f;
    }
    .file-list {
        margin-top: 34px;
    }
}
.page {
    position: absolute;
    background: @--background-color-base;
    height: 100%;
    width: 100%;
    .section {
        width: 720px;
    }
}
.xq-footer {
    margin-top: 30px;
}
.fileList /deep/ .btn-delete,
.fileList /deep/ .el-upload {
    display: none !important;
}
</style>
