/*
 * @Author: linyq1
 * @Email:linyq@hxmec.com 
 * @Date: 2019-11-01 17:34:31
 * @Last Modified by: zhujial
 * @Last Modified time: 2024-05-29 10:16:19
 * @Description: 用于门户的底部文件
 * @Route: Route
 */

<template>
    <div class="">
        <div class="xq-footer">
            <!-- <p v-if="domainInfo.managementUnit">
                {{ '管理单位：'+domainInfo.managementUnit }}
            </p> -->
            <!-- <p v-if="domainInfo.productionUnit">
                {{ '承建单位：'+domainInfo.productionUnit }}
            </p> -->
            <!-- <p v-if="domainInfo.technicalSupport">
                {{ '技术支持：'+domainInfo.technicalSupport }}
            </p> -->
        </div>
        <div class="xq-footer">
            <p v-if="domainInfo.copyright">
                <a :href="domainInfo.copyrightUrl"
                   class="b_link">{{ domainInfo.copyright }}</a>
            </p>
            <!-- <p v-if="domainInfo.hotLine">
                服务热线: {{ domainInfo.hotLine }}
            </p> -->
        </div>
    </div>
</template> 

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['domainInfo'])
    }
};
</script>

<style lang="less" scoped>
.b_link {
    color: inherit;
    text-decoration: none;
}
.xq-footer {
    text-align: center;
    p {
        display: inline-block;
        font-size: 14px;
        color: @--color-text-regular;
        padding: 5px 20px;
    }
}
</style>
