var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hx-header" },
    [
      _vm.$route.query.itemId || _vm.$route.query.tenditmId || _vm.proName
        ? _c(
            "a",
            { staticClass: "project-back", on: { click: _vm.backOuter } },
            [
              _vm._v(
                _vm._s(
                  _vm.btnBackPath.label ? _vm.btnBackPath.label : "返回项目外"
                )
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.header.show
        ? _c("div", { staticClass: "header-top" }, [
            _c("div", { staticClass: "hx-header_left" }, [
              _vm.header.logo
                ? _c("img", { attrs: { src: _vm.header.logo } })
                : _vm._e(),
              _vm._v(" "),
              _vm.webName
                ? _c("span", { staticClass: "header-top_title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.webName) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.proName
                ? _c("span", [
                    _vm._v(
                      "\n                项目名称：" +
                        _vm._s(_vm.proName) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hx-header_right" }, [
              _vm.domainInfo.hotLine
                ? _c("div", { staticClass: "xq-header-list__item" }, [
                    _c("i", { staticClass: "hxaxure hx-tell icon" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" " + _vm._s(_vm.domainInfo.hotLine))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.downloadFilterList && _vm.downloadFilterList.length !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "book-info",
                      on: {
                        click: function ($event) {
                          _vm.bookVisible = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "hxaxure" }, [_vm._v("")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                    帮助中心\n                "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message-box", on: { click: _vm.handleFull } },
                [
                  _c("img", {
                    staticClass: "message-box-img",
                    attrs: {
                      src: _vm.header.full
                        ? _vm.header.closeFullScreen
                        : _vm.header.openFullScreen,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "message-box-span" }, [
                    _vm._v(_vm._s(_vm.header.full ? "退出全屏" : "全屏模式")),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "user-box" }, [
                _c("img", { attrs: { src: _vm.header.user, alt: "" } }),
                _vm._v(" "),
                _c("div", { staticClass: "user-box_text" }, [
                  _c("span", { staticClass: "user-box--big" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.userInfo.orgName) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.userInfo.nickName) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user-box_side" }, [
                  _c("ul", [
                    _c("li", { on: { click: _vm.handlePersonalCenter } }, [
                      _vm._v(
                        "\n                             个人中心\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { on: { click: _vm.handleLogout } }, [
                      _vm._v(
                        "\n                            退出系统\n                        "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "head-right" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tab-pane-box",
              attrs: { type: "card", editable: "", value: _vm.currentPath },
              on: {
                "tab-click": (el) => _vm.handleTab(el.$options.propsData.name),
                "tab-remove": _vm.handlePageTagClose,
              },
            },
            _vm._l(_vm.pageTagsData, function (tag) {
              return _c(
                "el-tab-pane",
                { key: tag.path, attrs: { label: tag.label, name: tag.path } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(tag.label) +
                      _vm._s(tag.path) +
                      "\n            "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帮助文档下载",
            visible: _vm.bookVisible,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bookVisible = $event
            },
          },
        },
        [
          _c(
            "ol",
            { staticClass: "book-list" },
            _vm._l(_vm.downloadFilterList, function (item, i) {
              return _c("li", { key: i }, [
                _c("span", [_vm._v(_vm._s(i + 1))]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.download(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.name) +
                        "\n                    "
                    ),
                    _c("span", [
                      _c("i", { staticClass: "hxaxure" }, [
                        _vm._v(
                          "\n                            \n                        "
                        ),
                      ]),
                      _vm._v(
                        "\n                        下载\n                    "
                      ),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "基本资料",
            visible: _vm.showAccount,
            "append-to-body": "",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAccount = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "account-main" },
            [
              _c("bk-form", {
                ref: "form",
                attrs: { "form-config": _vm.formConfig },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.showPassword,
            "custom-class": "password-dialog",
            "append-to-body": "",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPassword = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "account-main" },
            [
              _c("bk-form", {
                ref: "form",
                attrs: { "form-config": _vm.formConfigPwd },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isShowHeader
        ? _c("div", {
            staticClass: "mask-header",
            on: {
              click: function ($event) {
                _vm.isShowHeader = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "710px",
            "custom-class": "global-msg-dialog",
            visible: _vm.msgVisible,
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.msgVisible = false
              _vm.globalMsgId = []
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("bk-title", { attrs: { label: "未读公告消息" } })]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "global-msg-dialog__footer",
                      on: { click: _vm.handleClickAllMsg },
                    },
                    [_vm._v("查看全部")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "ul",
            [
              _c("li", { staticClass: "global-msg-dialog__list" }, [
                _c("div", { staticClass: "global-msg-dialog__unread" }, [
                  _vm._v("未读公告"),
                  _c("span", { staticClass: "global-msg-dialog__unreadicon" }, [
                    _vm._v(_vm._s(_vm.messageGlobalPopupList.length)),
                  ]),
                  _vm._v("条"),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.computedMessageLeftList, function (item) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "global-msg-dialog__list global-msg-dialog__msg",
                    class: {
                      "global-msg-dialog__msg--active": +item.readFlag === 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleMsgClick(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "global-msg-dialog__radio" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "global-msg-dialog__content" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "global-msg-dialog__time" }, [
                      _vm._v(_vm._s(item.publishTime)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.infoVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "global-msg-dialog__docs",
              attrs: {
                visible: _vm.infoVisible,
                "append-to-body": "",
                width: "900px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.infoVisible = $event
                },
                close: _vm.handleMsgDetailClose,
              },
            },
            [
              _c(
                "div",
                [
                  _c("notice-info", {
                    attrs: { "has-header": false, params: _vm.noticeInfo },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }