import devConfig from './config.dev';
import prodConfig from './config.prod';
import preConfig from './config.pre';
import testConfig from './config.test';
import baseConfig from './config.base';


const env = process.env.CM_ENV || process.env.NODE_ENV;
let config = {};
switch (env) {
case 'production': {
    config = prodConfig;
    break;
}
case 'pre': {
    config = preConfig;
    break;
}
case 'development': {
    config = devConfig;
    break;
}
case 'test': {
    config = testConfig;
    break;
}
default: {
    break;
}
}

export default Object.assign(baseConfig, config);
