/*
 * @Author: qiuwt
 * @Email: qiuwt@guahao.com
 * @Date: 2019-11-06 15:07:18
 * @Last Modified by: zhujial
 * @Last Modified time: 2024-05-27 10:27:07
 * @Description: Description
 * @Route: Route
 */

<template>
    <div class="med-container"
         :class="{ 'mgr': visualFlag }">
        <div ref="container"
             class="navbar" />
        <common-header ref="header"
                       @changeMenu="changeMenu"
                       @route="getBreadcrumbData" />
        <div class="med-content">
            <div class="left-wrapper">
                <!-- <div class="menu-search-wrapper">
                    <el-input v-model="menuQuery">
                        <i slot="suffix"
                           class="el-icon-search el-input__icon"
                           @click="handleMenuSearch" />
                    </el-input>
                </div> -->
                <el-menu :default-active="routerIndex"
                         :class="['med-menu', isOutsideProject ? 'no-back-btn' : '']"
                         :style="{'bottom':'40px'}"
                         background-color="#0b56a3"
                         text-color="#fff"
                         active-text-color="#fff"
                         :collapse="!sideShow"
                         :default-openeds="openeds"
                         @select="handleSelect">
                    <SidebarMenu :menu-data="menuDataFilter"
                                 :default-active="routerIndex" />
                </el-menu>
                <div v-if="sideShow"
                     class="back-btn back-side1-btn">
                    <a v-if="userAppNum>1 || hasfromHost"
                       class="back-btn_1 "
                       @click="gotoPortal">
                        前往应用中心
                    </a>
                    <a @click="handleSide">
                        <i class="el-icon-d-arrow-left" />
                        收起
                    </a>
                </div>
                <div class="back-btn back-side2-btn"
                     @click="handleSide">
                    <!-- <span class="back-side2-right back-side2-span">收起</span> -->
                    <!-- <i class="back-side2-left el-icon-d-arrow-left" /> -->
                    <span class="back-side2-span">
                        展开
                    </span>
                    <i class="el-icon-d-arrow-right" />
                    <!-- <div class="back-side2-nav">
                        <section class="back-side2-nav_con">
                            <a v-if="userAppNum>1"
                               class="back-btn_1 "
                               @click="gotoPortal">
                                前往应用中心
                            </a>
                            <a @click="handleSide">
                                <i class="el-icon-d-arrow-left" />
                                展开
                            </a>
                        </section>
                    </div> -->
                </div>
            </div>
            <div class="right-wrapper">
                <!-- 面包屑 -->
                <el-breadcrumb v-if="breadcrumbData && breadcrumbData.length > 1"
                               class="med-breadcrumb">
                    <!-- 规避面包屑最后一级多一个右箭头的问题 -->
                    <template v-if="backUrlData">
                        <router-link class="btn-back fr"
                                     :to="backUrlData">
                            返回
                        </router-link>
                    </template>
                    <!-- <template v-else>
                        <span class="btn-back fr"
                              @click="handleBack">返回</span>
                    </template> -->
                    <span class="breadcrumb-desc">当前位置：</span>
                    <template v-for="(item, index) in breadcrumbData">
                        <el-breadcrumb-item v-if="breadcrumbData.length === (index+1)"
                                            :key="index">
                            {{ item.label }}
                        </el-breadcrumb-item>
                        <el-breadcrumb-item v-else
                                            :key="index"
                                            :to="{ path: item.fullPath|| item.path}">
                            {{ item.label }}
                        </el-breadcrumb-item>
                    </template>
                </el-breadcrumb>
                <!-- 右侧主体区域 -->
                <div :class="[{'has-breadcrumb': breadcrumbData.length > 0}, 'right-content']">
                    <!--主体部分-->
                    <!-- 缓存主页 -->
                    <keep-alive>
                        <router-view v-if="$route.path === '/message'" />
                    </keep-alive>
                    <!-- 内容页不缓存 -->
                    <router-view v-if="$route.path !== '/message'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import Routes from '@/router';
import CommonHeader from '@/components/header';
import SidebarMenu from '@/components/sidebar-menu';
import { findTargetByDeepTraverse, isEmptyObj, formatDate } from '@/utils/utils';
import Queue from '@/utils/queue';
import { gotofromHost } from '@/utils/fetch';
import Watermark from '@/utils/waterMark';

export default {
    components: {
        CommonHeader,
        SidebarMenu
    },
    data() {
        return {
            sideShow: true, // 是否菜单
            menuData: [], // 菜单数据
            menuSourceData: [], // 菜单数据
            routerIndex: '', // 当前菜单
            /**
             * 面包屑数据
             */
            breadcrumbData: [],
            /**
             * 所以页签的面包屑数据
             */
            backUrlData: '', // 面包屑返回路径
            // menuCode: '', // 缓存菜单id
            menuQuery: '', // 搜索菜单数据
            matchDataArr: [], // 匹配的数据
            routeData: [], // 路由数据
            insideProjectData: ['portal'],
            noUpmsMenuApp: ['/aup', '/tender', '/hxupsc', '/report'],
            mgrVisualPathArr: [
                '/jlmgr/visual/home',
                '/mgr/visual/home',
                '/mgr/visual/province-enterssells-saves',
                '/mgr/visual/region-enterssells-saves',
                '/mgr/visual/hospital-enterssells-saves',
                '/report/quantity-manage/fill/import-preview',
                '/report/historical-data-manage/historical-fill/import-preview'
            ],
            // 监管大屏
            visualFlag: false,
            hasfromHost: window.localStorage.getItem('fromHost')
        };
    },
    computed: {
        ...mapGetters({
            applyMeunList: 'applyMeunList',
            userInfo: 'userInfo',
            projectApi: 'projectApi',
            pageIndex: 'index',
            userAppNum: 'userAppNum' // 应用数量
        }),
        // 返回主页
        projectBack() {
            return {
                path: '/portal',
                label: '返回主页'
            };
        },
        isOutsideProject() {
            const { path } = this.$route;
            let status = false;
            this.insideProjectData.forEach(item => {
                if (path.indexOf(item) !== -1) {
                    status = true;
                }
            });
            return status;
        },
        isMainPage() {
            return isEmptyObj(this.$route.query);
        },
        apiProjectMuen() {
            return JSON.stringify(this.projectApi);
        },
        menuDataFilter() {
            return this.breadSearchMenuFilter(this.menuData);
        },
        // 默认展开子菜单
        openeds() {
            if (!this.menuDataFilter || !Array.isArray(this.menuDataFilter)) return [];
            return this.menuDataFilter.map(item => item.id || item.path);
        } 
    },
    watch: {
        // 同步菜单
        applyMeunList(val) {
            if (!val.length || JSON.stringify(this.menuSourceData) === JSON.stringify(val)) return;
            const current = val.length === 1 ? val[0].children : val;
            this.$store.commit('SET_PROJECT', val[0].name);
            if (!current) return;
            this.menuData = current;
            this.menuSourceData = JSON.parse(JSON.stringify(val));
            // 单菜单唯一时调整
            this.gotoOnlyRoute(current);
        },
        pageIndex(val, old) {
            if (!val && val === old) return;
            this.gotoIndex(val);
        },
        userInfo: {
            handler() {
                this.setWatermark();
            },
            deep: true
        }
    },
    created() {
        this.initStatus();
    },
    mounted() {
        if (this.$route.query.from) {
            localStorage.setItem('referrer', decodeURIComponent(this.$route.query.from));
        }
        if (this.$route.query.auth) {
            localStorage.setItem('authUrl', decodeURIComponent(this.$route.query.auth));
        }
        // 过滤集采服务
        const { path } = this.$route;
        const isUpmsMenu = this.noUpmsMenuApp.filter(item => {
            const reg = new RegExp(`^${item}`);
            if (path.match(reg) && !path.match(/^\/aupqc/)) {
                return true;
            }
            return false;
        });
        this.$nextTick(() => {
            // 项目测试中勿改
            if (!isUpmsMenu.length) this.getMenu();
            // 国家局监管大屏 med-container 样式定制
            if (this.mgrVisualPathArr.includes(this.$route.path)) {
                this.visualFlag = true;
            } else {
                this.visualFlag = false;
            }
        });
        this.setWatermark();
    },
    methods: {
        ...mapActions(['fetchApplyMenu']),
        // 初始化当前状态
        initStatus() {
            document.body.dataset.side = true;
        },
        setWatermark() {
            const date = formatDate(new Date(), 'yyyy年mm月dd日');
            // nickName--用户名称   orgName-机构名称
            const { nickName, orgName, username } = this.userInfo;
            Watermark.set(`${nickName} ${username}\n${orgName}\n${date}`, this.$refs.container);
        },
        // 浏览器icon配置
        browerIcon(iconId) {
            if (document.querySelector('head > link[type="image/x-icon"]')) {
                return;
            }
            this.$fetch({
                url: this.$bkConfig.filePath,
                params: {
                    id: iconId
                }
            }).then(res => {
                if (res.data) {
                    const icon = res.data.url;
                    const dom = document.createElement('link');
                    dom.setAttribute('rel', 'shortcut icon');
                    dom.setAttribute('href', icon);
                    dom.setAttribute('type', 'image/x-icon');
                    document.getElementsByTagName('head')[0].appendChild(dom);
                }
            });
        },
        gotoIndex(val) {
            const { path } = this.$route;
            const current = path.split('/');
            if (current.length < 2 || !current[2]) {
                // 前往首页
                this.$router.replace(val);
            }
        },
        gotoPortal() {
            this.$store.commit('SET_INNER_PRO_NAME', ''); // 清除项目名
            this.$store.commit('CLEAN_INNER_PRO_NAME'); // 清除项目名称
            // const referrer = localStorage.getItem('fromHost');
            // if (referrer) {
            //     localStorage.removeItem('fromHost');
            //     window.location.href = referrer;
            // } else {
            gotofromHost(this.projectBack.path, this, localStorage.getItem('appCenter'));
            // }
        },
        // 切换显示菜单
        handleSide() {
            this.sideShow = !this.sideShow;
            document.body.dataset.side = this.sideShow;
        },
        /**
         * 全屏切换控制菜单显示
         * */ 
        changeMenu(menuStatus) {
            this.sideShow = menuStatus;
            document.body.dataset.side = menuStatus;
        },
        /**
         * 前往唯一页面
         * @param { Array } arr 菜单
         */
        gotoOnlyRoute(arr) {
            const { path } = this.$route;
            const flag = path.split('/');
            if (flag.length === 2) {
                const route = this.getOnlyRoute(arr);
                // 自动跳转
                if (route) this.$router.replace(route);
            }
        },
        /**
         * 自动打开首页
         * @param {Arr}
         */
        getOnlyRoute(arr) {
            const path = '';
            if (arr.length === 1) {
                const [item] = arr;
                if (item.children) {
                    return this.getOnlyRoute(item.children);
                }
                return item.path;
            }
            return path;
        },

        /**
         * 获得面包屑
         */
        getBreadcrumbData(ref) {
            this.breadcrumbData = ref.breadcrumbData;
            if (this.breadcrumbData.length) {
                this.routerIndex = this.breadcrumbData[0].path;
                // 数据分析的菜单有带参数，(同一个路由通过参数来区分)
                if (this.breadcrumbData[0].path === '/analysis/my-view/view-main') {
                    this.routerIndex = this.breadcrumbData[0].fullPath;
                }
            }
        },
        /**
         * 检查数组中是否被目标地址包含
         */
        isHasMenu(target, data) {
            let bool = false;
            for (let i = 0; i < data.length; i += 1) {
                const item = data[i];
                if (item.children) {
                    bool = this.isHasMenu(target, item.children);
                    if (bool) break;
                } else if (target.indexOf(item.path) > -1) {
                    bool = true;
                    break;
                }
            }
            return bool;
        },
        /*
         * 针对外链调用iframe进行渲染
         */
        handleSelect(path) {
            if (path.toString().startsWith('http')) {
                this.$router.push({
                    name: 'Iframe',
                    params: {
                        URL: path
                    }
                });
            } else if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },
        /*
         * 返回上一页
         */
        handleBack() {
            this.$router.history.go(-1);
        },
        /*
         * 匹配面包屑数据
         * @param {String} path 当前需要匹配的一级页面的路由
         * @param { Arr } 所有页面的路由信息
         */
        match(path, arr) {
            if (Array.isArray(arr)) {
                arr.forEach(item => {
                    if (Array.isArray(item.children) && item.children.length) {
                        this.match(path, item.children);
                    } else if (item.path.includes(path)) {
                        this.matchDataArr.push({
                            path: item.path,
                            label: item.name
                        });
                    }
                });
            }
        },
        /**
         * 加载菜单
         * @param {String} api 项目内外地址
         */
        getMenu() {
            // 如果有replaceParent就用replaceParent替换当前的路由，让选中的菜单高亮显示。
            const api = 'fetchApplyMenu';
            const params = {};
            if (this.menuData.length) return;
            // get server data
            this[api](params).then(
                res => {
                    if (res && res.data) {
                        // 设置当前或面包屑首级地址
                        this.$store.commit('SET_APP_NUM', res.data.length);
                        // 首页地址
                        this.backUrlData = this.$route.meta.backUrl;
                    }
                },
                err => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
            );
        },
        // 获取所以的菜单
        getAllMenu() {
            // 全部菜单打开
            this.menuData = this.createTreeMenu(1);
            // console.log(JSON.stringify(this.menuData[4]));
            this.backUrlData = this.$route.meta.backUrl;
            this.menuSourceData = JSON.parse(JSON.stringify(this.menuData));
        },
        // 获取全部菜单
        getAllMenuJson() {
            const data = this.createTreeMenu(1);
            this.toServerMenuJson(data);
            console.log(JSON.stringify(data));
            return JSON.stringify(data);
        },
        // 转换成功服务器格式
        toServerMenuJson(arr) {
            arr.forEach((obj, index) => {
                obj.sort = index + 1;
                if (obj.name) obj.name = obj.name.replace(/-(.*)/, '');
                if (obj.children && obj.children.length) {
                    obj.type = '0';
                    this.toServerMenuJson(obj.children);
                }
            });
        },
        /*
         * 根据扁平数据生成树形菜单
         * @param {Array} data 所有路由的数据
         * @param {Array} menuData 树形菜单数据
         * @param {Number} type 生成数据类型（1: 不包含二级三级数据，2: 包含二级三级数据源）
         */
        createTreeMenu(type, data = Routes[0], menuData = []) {
            if (!Array.isArray(data.children) || !data.children.length) return null;
            data.children.forEach(obj => {
                const item = obj;
                let menuItem = {};
                if (Array.isArray(item.children) && item.children.length) {
                    item.parentPath = data.parentPath ? `${data.parentPath}/${item.path}` : item.path;
                    menuItem = {
                        path: item.path,
                        name: item.meta ? item.meta.title : '',
                        children: []
                    };
                    menuData.push(menuItem);
                    return this.createTreeMenu(type, item, menuItem.children);
                }
                if (type === 1 && item.meta.display && item.meta.display === 'none') return '';
                let { parentPath } = data;
                if (parentPath) {
                    parentPath = data.parentPath.replace(/^\//, ''); // 绝对地址
                }
                menuItem = {
                    path: parentPath ? `/${parentPath}/${item.path}` : `/${item.path}`,
                    name: item.meta.title
                };
                menuData.push(menuItem);
                return '';
            });
            return menuData;
        },

        /*
         * 菜单搜索
         */
        handleMenuSearch() {
            const { menuQuery, menuSourceData } = this;
            if (!menuQuery) {
                this.menuData = menuSourceData;
            } else {
                this.menuData = [...findTargetByDeepTraverse(menuSourceData, menuQuery)];
            }
        },
        breadSearchMenuFilter(tree) {
            if (!tree) {
                return null;
            }
            const queue = new Queue();
            tree.forEach(item => {
                queue.push(item);
            });
            while (queue.top) {
                const item = queue.shift();
                if (item.children) {
                    const result = item.children.filter(i => {
                        if (i.type === '1') {
                            return false;
                        }
                        return true;
                    });
                    item.hasChildren = true;
                    if (result.length === 0) {
                        item.hasChildren = false;
                    }
                    item.children.forEach(cell => {
                        queue.push(cell);
                    });
                }
            }
            return tree;
        }
    }
};
</script>

<style lang='less' scoped>
.med-container {
    // 解决整体窗口溢出情况
    overflow: hidden;
    /deep/ .el-container {
        overflow: hidden;
    }
    /deep/ .el-breadcrumb__inner.is-link {
        color: @--color-primary;
        font-weight: 400;

        &:hover {
            color: @--color-primary;
            font-weight: 700;
        }
    }
}
.left-wrapper {
    position: relative;
    float: left;
    width: 256px;
    height: 100%;
    transition: width linear 0.3s;
    background-color: @--background-color-menu-light;
    z-index: 99;
    .el-menu {
        border: 0;
    }
    /deep/ .el-submenu__title {
        height: 40px;
        line-height: 40px;
        color: @--side-color !important;
        i {
            color: inherit;
        }
    }
    /deep/ .el-menu-item {
        color: @--side-color !important;
    }
    /deep/ .med-submenu {
        .el-menu-item,
        .el-submenu__title {
            background-color: @--background-color-menu-base !important;

            &:hover,
            &.is-active {
                background-color: @--color-primary !important;
                color: @--color-white !important;
            }
        }
    }
}

/deep/.el-breadcrumb__separator {
    margin: 0 2px 0 1px;
}
.med-menu {
    position: absolute;
    top: 0;
    bottom: 66px;
    width: 100%;
    overflow: auto;
}
.no-back-btn {
    bottom: 0;
}
.back-btn {
    position: absolute;
    width: 100%;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    background-color: @--background-color-menu-base;
    border-top: 1px solid @--side-color;
    color: @--side-color;
    display: flex;
    cursor: pointer;
    a {
        flex: 1 1 auto;
        text-align: center;
        cursor: pointer;
        &:hover {
            background: @--side-btn-bg;
        }
    }
    .back-btn_1 {
        border-right: 1px solid @--side-color;
        flex: none;
        width: 135px;
    }
    &.back-side2-btn {
        display: none;
    }
    .back-side2-span {
        padding: 2px;
    }
}
::-webkit-scrollbar {
    //滚动条的宽度
    width: 1px;
    height: 1px;
}
.menu-search-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 9px 16px;
    height: 50px;
    box-sizing: border-box;
    background-color: @--background-color-menu-base;

    /deep/ .el-input {
        border-radius: 4px;
        overflow: hidden;

        .el-input__inner {
            padding-right: 40px;
            height: 32px;
            line-height: 32px;
            background-color: @--background-color-menu-light;
            border: 0;
            color: @--color-white;
        }
        .el-input__suffix {
            right: 0;
        }
        .el-input__icon {
            width: 40px;
            line-height: 32px;
            font-size: 16px;
            color: @--color-white;
            background-color: @--color-primary;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.med-breadcrumb {
    padding-bottom: 8px;
    margin-top: -8px;
    font-size: @--font-size-extra-small;
}
.breadcrumb-desc {
    float: left;
    color: @--color-text-secondary;
}
.right-content {
    background-color: @--color-white;
    position: relative;
    padding: 5px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}
.right-content.has-breadcrumb {
    // height: calc(100% - 12px);
    overflow-y: auto;
}
.btn-back {
    cursor: pointer;
}
</style>
