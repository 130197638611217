export default {
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'msg', // 响应文案信息
        successCode: 0 // 成功码
    },
    responseListProps: {
        results: 'records', // 响应数组数据
        pageSize: 'pageSize', // 页长
        pageNumber: 'pageNo', // 页码
        totalCount: 'count', // 页面数据总量
        cursorCode: 'cursorCode' // 游标标记字段-没用
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'id', // 文件标识字段
        fileUrl: 'url', // 文件地址
        fileName: 'originName', // 文件名
        fileSuffix: 'ext' // 文件后缀
    },
    // 弹出框不能点击遮罩关闭配置
    dialogExtend: {
        'close-on-click-modal': false
    },
    inputLength: 100, // 输入框限制最大输入
    vcodePath: '/code', // 验证码
    uploadPath: '/code/base/fe/upload', // 上传
    filePath: '/code/base/fe/info', // 文件信息
    hxgw: '/code/hxgw', // 医药云网关，请求医药 token用
    downloadPath: '/code/base/fe/download' // 下载资源用
};
