
 
const watermark = {};
/**
 * 根据字符串长度获取width
 * @param {分割后的字符串数组} strs
 */
function handleWidth(strs, cans) {
    const maxLenStr = strs.reduce((a, b) => (a.length > b.length ? a : b));
    return cans.measureText(maxLenStr).width + 20;
}
/**
 *
 * @param {要设置的水印的内容} str
 * @param {需要设置水印的容器} container
 */
function setWatermark(str, container) {
    const id = '1.23452384164.123412415';
 
    if (container === undefined) {
        return '';
    }
 
    // 查看页面上有没有，如果有则删除
    if (document.getElementById(id) !== null) {
        const childelement = document.getElementById(id);
        childelement.parentNode.removeChild(childelement);
    }
 
    // const containerWidth = container.offsetWidth; // 获取父容器宽
    // const containerHeight = container.offsetHeight; // 获取父容器高
    // container.style.position = 'relative'; // 设置布局为相对布局
    // 将字符串分割成多行
    const lines = str.split('\n').filter(Boolean);
    const marginLeft = 20;
    const marginRight = 100;
    // 创建canvas元素(先制作一块背景图)
    const can = document.createElement('canvas');
    // can.width = handleWidth(lines) + marginRight; // 设置每一块的宽度
    can.height = 160; // 高度
    const cans = can.getContext('2d'); // 获取canvas画布
    cans.font = '14px Source Han Sans CN'; // 设置字体的大小
    const textWidth = handleWidth(lines, cans); // 动态获取文本宽度
    can.width = textWidth + marginLeft + marginRight; // 设置每一块的宽度

    const centerX = textWidth / 2 + marginLeft;
    const centerY = can.height / 2;
    cans.translate(centerX, centerY); // 将画布的原点定位到中心
    cans.rotate(-20 * Math.PI / 180); // 逆时针旋转π/9
    // cans.fillStyle = 'rgba(25, 181, 217, 0.8)'; // 设置字体的颜色
    cans.fillStyle = '#7AB4EF'; // 设置字体的颜色
    cans.font = '14px Source Han Sans CN'; // 设置字体的大小
    cans.textAlign = 'center'; // 文本对齐方式
    cans.textBaseline = 'Middle'; // 文本基线

    // 渲染多行字符串
    const lineHeight = 20;
    for (let i = 0; i < lines.length; i += 1) {
        // 绘制每一行, 并增加偏移量.
        cans.fillText(lines[i], 0, lineHeight * i);
    }
    // cans.fillText(str, 0, 4 * can.height / 5); // 绘制文字
    cans.translate(-centerX, -centerY); // 将画布的原点还原
    // 创建一个div元素
    const div = document.createElement('div');
    div.id = id; // 设置id
    div.style.pointerEvents = 'none'; // 取消所有事件
    div.style.top = '0px';
    div.style.left = '0px';
    div.style.position = 'absolute';
    div.style.zIndex = '100000';
    div.style.width = `${document.documentElement.clientWidth}px`;
    div.style.height = `${document.documentElement.clientHeight}px`;
    //   div.style.width = containerWidth + 'px'
    //   div.style.height = containerHeight + 'px'
    div.style.background = `url(${can.toDataURL('image/png')}) left top repeat`;
    div.style.opacity = '0.15';
    container.appendChild(div); // 追加到页面
 
    return id;
}
 
// 该方法只允许调用一次
watermark.set = (str, container) => {
    let id = setWatermark(str, container);
    setInterval(() => {
        if (document.getElementById(id) === null) {
            id = setWatermark(str, container);
        }
    }, 500);
    // 监听页面大小的变化
    window.onresize = () => {
        setWatermark(str, container);
    };
};
 
export default watermark;
