/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-02-04 17:21:39
 * @Last Modified by: Danielz
 * @Last Modified time: 2023-05-19 10:19:43
 * @Description: 入口基础抽离，减少不必要的打包
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config';
import './styles/normalize';
import './styles/common';
import './styles/framework';
import { getUrlParame } from '@/utils/utils';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import routes from './router';

const init = cb => {
    Vue.config.productionTip = false;
    Vue.config.silent = process.env.NODE_ENV !== 'development';
    try {
        setPublicPath('navbar'); // 注册应用
    } catch (e) {
        console.log(e, 'setPublicPath错误');
    }
    const router = new VueRouter({
        mode: 'history',
        // mode:'hash',
        routes
    });
    Vue.use(VueRouter);
    Vue.use(Element);
    const store = storeData;
    window.Store = store;
    // 生产模式上报
    // Sentry.init({
    //     Vue,
    //     dsn: 'http://92362d32cb334ae7bb0293d808194b17@sentry.udplat.com/20',
    //     environment: process.env.NODE_ENV,
    //     integrations: [
    //         new Integrations.BrowserTracing({
    //             routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    //             // tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
    //         })
    //     ],
    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0
    // });


    // 添加路由信息
    store.commit('SAVE_ROUTE_DATA', routes[0].children); // 保存路由信息到全局
    Vue.mixin({
        methods: {
            $fetch(options) {
                return this.$root.fetch(options);
            }
        }
    });
    // Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper upms">
            <router-view></router-view>
        </div>,
        data() {
            return {
                tokenTime: 0 // 当前token有效时间
            };
        },
        computed: {
            bkConfig: () => configs,
            // 三级等保
            isDB() {
                return window.location.host.indexOf('db.') > -1;
            }
        },
        watch: {
            $route(val) {
                // toke 刷新
                const token = localStorage.getItem('token');
                if (token && val.path !== '/login') {
                    this.tokenTime = new Date().getTime();
                    document.addEventListener('mousemove', this.checkToken);
                } else if (val.path === '/login') {
                    document.removeEventListener('mousemove', this.checkToken);
                }
            }
        },
        created() {
            window.App = this;
        },
        destroy() {
            document.removeEventListener('mousemove', this.checkToken);
        },
        methods: {
            fetch: config => fetch(config),
            // 尝试刷新token
            checkToken() {
                const nowTime = new Date().getTime();
                const s = nowTime - this.tokenTime;
                const isMaxTime = s > 1000 * 60 * 1;
                if (isMaxTime) {
                    // 尝试激活token
                    this.tokenTime = new Date().getTime();
                    this.$fetch({
                        // url: '/code/upms/login/check/status',
                        url: '/upms/login/check/status',
                        method: 'post'
                    });
                }
            }
        },
        store,
        router
    };

    // 获得连接上的参数
    let tokenFromUrl = getUrlParame() || {};
    router.beforeEach((to, from, next) => {
        // 第三方退出会带token=null，这里进行过虑

        if ([tokenFromUrl.token, to.query.token].indexOf('null') > -1) {
            localStorage.removeItem('token', '');
            // 菜单
            localStorage.removeItem('system_id');
            tokenFromUrl = {};
            next({
                path: '/login'
            });
        } else {
            // 非登录路由前缀
            const loginPath = ['login', 'register', 'health', 'reset', 'notice'];
            // 获得路由前缀 判断需不需要跳转
            const path = to.path.split('/')[1];
            // 外链信息接收
            if (tokenFromUrl.token) {
                // 菜单
                localStorage.removeItem('system_id');
                localStorage.removeItem('fromHost');
                localStorage.removeItem('appCenter');
                Object.keys(tokenFromUrl).forEach(key => {
                    const val = tokenFromUrl[key];
                    localStorage.setItem(key, val);
                });
                // 清楚用户信息
                sessionStorage.clear();
            }
            const token = localStorage.getItem('token');
            // 重定向登录路由
            if (!token && loginPath.indexOf(path) === -1) {
                // 重定向到登录
                next({
                    path: '/login'
                    // query: {
                    //     path: to.fullPath
                    // }
                });
            } else {
                next();
            }
        }
        tokenFromUrl = {};
    });
    // 接受外部配置
    if (cb) cb(Vue);
    const vueLifecycles = singleSpaVue({
        Vue,
        appOptions
    });

    return vueLifecycles;
};

export default init;
