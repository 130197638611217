/*
 * @Author: qiuwt
 * @Email: qiuwt@guahao.com
 * @Date: 2019-08-06 09:36:40
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-12-14 14:03:54
 * @Description: 权限管理
 */
import View from '@/components/view';

// const OrgList = () => import(/* webpackChunkName: "org-list" */ '@/pages/purview/user-manage/org-list');
// const ExpertList = () => import(/* webpackChunkName: "expert-list" */ '@/pages/purview/user-manage/expert-list');
// const PurviewRoleList = () => import(/* webpackChunkName: "role-list" */ '@/pages/purview/purview-manage/role-list');
// const PurviewRoleDetail = () => import(/* webpackChunkName: "role-detail" */ '@/pages/purview/purview-manage/role-detail');
// const PurviewMenu = () => import(/* webpackChunkName: "menu-list" */ '@/pages/purview/purview-manage/menu-list');

// const AccountList = () => import(/* webpackChunkName: "account-list" */ '@/pages/purview/base-manage/account-list');
// const RoleList = () => import(/* webpackChunkName: "role-list" */ '@/pages/purview/base-manage/role-list');
// const AssignAccountList = () => import(/* webpackChunkName: "assign-account-list" */ '@/pages/purview/base-manage/assign-account-list');
// const AddRole = () => import(/* webpackChunkName: "add-role" */ '@/pages/purview/base-manage/add-role');

const accountInfo = () => import(/* webpackChunkName: "accountInfo" */ '@/pages/purview/base-manage/account-info');
const changeRecords = () => import(/* webpackChunkName: "changeRecords" */ '@/pages/purview/base-manage/change-records');

const accountInfoExpress = () => import(/* webpackChunkName: "accountInfoExpress" */ '@/pages/purview/base-manage/account-info-express');
const changeRecordsExpress = () => import(/* webpackChunkName: "changeRecordsExpress" */ '@/pages/purview/base-manage/change-records-express');
const accountInfoProduct = () => import(/* webpackChunkName: "accountInfoProduct" */ '@/pages/purview/base-manage/account-info-product');
const changeRecordsProduct = () => import(/* webpackChunkName: "changeRecordsProduct" */ '@/pages/purview/base-manage/change-records-product');
const accountInfoManagement = () => import(/* webpackChunkName: "accountInfoManagement" */ '@/pages/purview/base-manage/account-info-management');

const AccountManagement = () => import(/* webpackChunkName: "AccountManagement" */ '@/pages/purview/account-management/account-management');
const platformAccountManagement = () => import(/* webpackChunkName: "platformAccountManagement" */ '@/pages/purview/account-management/platform-account-management');
const platformAccountBate = () => import(/* webpackChunkName: "platformAccountManagement" */ '@/pages/purview/account-management/platform-account-bate');

const accountManagementUnit = () => import(/* webpackChunkName: "platformAccountManagement" */ '@/pages/purview/account-management/account-management-unit');


const roleManagement = () => import(/* webpackChunkName: "roleManagement" */ '@/pages/purview/purview-manage/role-management');
const platformRoleManagement = () => import(/* webpackChunkName: "platformRoleManagement" */ '@/pages/purview/purview-manage/platform-role-management');
const userAdd = () => import(/* webpackChunkName: "userAdd" */ '@/pages/purview/account-management/user-add');

const loginLogSearch = () => import(/* webpackChunkName: "loginLogSearch" */ '@/pages/purview/log-management/login-log-search');
const reviewLog = () => import(/* webpackChunkName: "review-log" */ '@/pages/purview/log-management/review-log');
const MessageList = () => import(/* webpackChunkName: "MessageList" */ '@/pages/purview/message-list');
const PageBaseInfo = () => import(/* webpackChunkName: "PageBaseInfo" */ '@/pages/purview/domain-login-set/page-base-info');
const navInfoList = () => import(/* webpackChunkName: "nav-info-list" */ '@/pages/purview/domain-login-set/nav-info-list');
const orgManage = () => import(/* webpackChunkName: "org-manage" */ '@/pages/purview/org-management/org-manage');
const orgManagBate = () => import(/* webpackChunkName: "org-manage-bate" */ '@/pages/purview/org-management/org-manage-bate');
const orgManagUnit = () => import(/* webpackChunkName: "org-manage-unit" */ '@/pages/purview/org-management/org-manage-unit');
const orgManagEnterprise = () => import(/* webpackChunkName: "org-manage-enterprise" */ '@/pages/purview/org-management/org-manage-enterprise');
const orgManagImportPreview = () => import(/* webpackChunkName: "org-manage-import-preview" */ '@/pages/purview/org-management/import-preview');
const orgManagImportPreviewUnit = () => import(/* webpackChunkName: "org-manage-import-preview-unit" */ '@/pages/purview/org-management/import-preview-unit');
const personalCenter = () => import(/* webpackChunkName: "personal-center" */ '@/pages/purview/personal-center');
export default [
    {
        path: '/purview',
        component: View,
        meta: {
            title: '权限管理',
            noTag: true
        },
        children: [
            {
                path: 'tenant-configuration',
                component: View,
                meta: {
                    title: '租户配置'
                },
                children: [
                    {
                        path: 'menu-configuration',
                        component: () => import(/* webpackChunkName: "MenuConfiguration" */ '@/pages/purview/tenant-configuration/menu-configuration'),
                        meta: {
                            title: '菜单配置'
                        }
                    },
                    {
                        path: 'project',
                        component: () => import(/* webpackChunkName: "org-manage-project" */ '@/pages/purview/tenant-configuration/project'),
                        meta: {
                            title: '所属项目配置'
                        }
                    }
                ]
            },
            // {
            //     path: 'user-manage',
            //     component: View,
            //     meta: {
            //         title: '用户管理'
            //     },
            //     children: [
            //         {
            //             path: 'org-list',
            //             component: OrgList,
            //             meta: {
            //                 title: '机构主账号管理'
            //             }
            //         },
            //         {
            //             path: 'expert-list',
            //             component: ExpertList,
            //             meta: {
            //                 title: '专家账号管理'
            //             }
            //         }
            //     ]
            // },
            {
                path: 'base-manage',
                component: View,
                meta: {
                    title: '基本资料'
                },
                children: [
                    {
                        path: 'account-info',
                        component: accountInfo,
                        meta: {
                            title: '机构基本资料'
                        }
                    },
                    {
                        path: 'account-info/org-base-info',
                        component: () => import(/* webpackChunkName: "OrgBaseInfo" */ '@/pages/purview/base-manage/org-base-info'),
                        meta: {
                            title: '机构基本资料-贯标数据',
                            display: 'none',
                            noTag: true
                        }
                    },
                    {
                        path: 'account-info/change-records',
                        component: changeRecords,
                        meta: {
                            title: '历史变更信息',
                            display: 'none',
                            noTag: true
                        }
                    },
                    {
                        path: 'account-info-express',
                        component: accountInfoExpress,
                        meta: {
                            replaceParent: 'account-info',
                            title: '机构基本资料',
                            display: 'none'
                        }
                    },
                    {
                        path: 'account-info-express/change-records-express',
                        component: changeRecordsExpress,
                        meta: {
                            title: '历史变更信息',
                            display: 'none',
                            noTag: true
                        }
                    },
                    {
                        path: 'account-info-product',
                        component: accountInfoProduct,
                        meta: {
                            replaceParent: 'account-info',
                            title: '机构基本资料',
                            display: 'none'
                        }
                    },
                    {
                        path: 'account-info-product/change-records-product',
                        component: changeRecordsProduct,
                        meta: {
                            title: '历史变更信息',
                            display: 'none',
                            noTag: true
                        }
                    },
                    {
                        path: 'account-info-management',
                        component: accountInfoManagement,
                        meta: {
                            title: '机构基本资料',
                            display: 'none'
                        }
                    },
                    {
                        path: 'review-base-info-manage',
                        component: () => import(/* webpackChunkName: "RBIM" */ '@/pages/purview/base-manage/review-base-info-manage'),
                        meta: {
                            title: '基本资料审核'
                        }
                    },
                    {
                        path: 'review-base-info-operating',
                        component: () => import(/* webpackChunkName: "RBIO" */ '@/pages/purview/base-manage/review-base-info-operating'),
                        meta: {
                            title: '基本资料审核'
                        }
                    }
                    // {
                    //     path: 'account-list',
                    //     component: AccountList,
                    //     meta: {
                    //         title: '账号管理'
                    //     }
                    // },
                    // {
                    //     path: 'role-list',
                    //     component: RoleList,
                    //     meta: {
                    //         title: '账号角色管理'
                    //     }
                    // },
                    // {
                    //     path: 'role-list/assign-list',
                    //     component: AssignAccountList,
                    //     meta: {
                    //         title: '分配账号',
                    //         display: 'none'
                    //     }
                    // },
                    // {
                    //     path: 'role-list/add-role',
                    //     component: AddRole,
                    //     meta: {
                    //         title: '添加角色',
                    //         display: 'none'
                    //     }
                    // }
                ]
            },
            {
                path: 'account-manage',
                component: View,
                meta: {
                    title: '账号管理'
                },
                children: [
                    {
                        path: 'account-management',
                        component: AccountManagement,
                        meta: {
                            title: '本机构账号管理'
                        }
                    },
                    {
                        path: 'platform-account-management',
                        component: platformAccountManagement,
                        meta: {
                            title: '平台账号管理'
                        }
                    },
                    {
                        path: 'platform-account-bate',
                        component: platformAccountBate,
                        meta: {
                            title: '医疗机构账号管理'
                        }
                    },
                    {
                        path: 'account-management-unit',
                        component: accountManagementUnit,
                        meta: {
                            title: '管理单位账号管理'
                        }
                    },
                    {
                        path: 'platform-account-management/user-add',
                        component: userAdd,
                        meta: {
                            title: '批量新增',
                            display: 'none',
                            noTag: true
                        }
                    }

                ]
            },
            {
                path: 'domain-login-set',
                component: View,
                meta: {
                    title: '域名登陆页配置'
                },
                children: [
                    {
                        path: 'page-base-info',
                        component: PageBaseInfo,
                        meta: {
                            title: '平台登陆页设置'
                        }
                    },
                    {
                        path: 'nav-info-list',
                        component: navInfoList,
                        meta: {
                            title: '登录页头导航配置'
                        }
                    }
                ]
            },
            {
                path: 'purview-manage',
                component: View,
                meta: {
                    title: '权限管理'
                },
                children: [

                    {
                        path: 'role-management',
                        component: roleManagement,
                        meta: {
                            title: '本机构角色管理'
                        }
                    },
                    {
                        path: 'plateform-role-management',
                        component: platformRoleManagement,
                        meta: {
                            title: '平台角色管理'
                        }
                    }

                    // {
                    //     path: 'role-list',
                    //     component: PurviewRoleList,
                    //     meta: {
                    //         title: '机构角色权限管理'
                    //     }
                    // },
                    // {
                    //     path: 'role-list/role-detail',
                    //     component: PurviewRoleDetail,
                    //     meta: {
                    //         title: '机构角色'
                    //     }
                    // },
                    // {
                    //     path: 'menu-list',
                    //     component: PurviewMenu,
                    //     meta: {
                    //         title: '菜单管理'
                    //     }
                    // }

                ]
            },
            {
                path: 'log-management',
                component: View,
                meta: {
                    title: '日志管理'
                },
                children: [
                    {
                        path: 'login-log-search',
                        component: loginLogSearch,
                        meta: {
                            title: '登录日志查询'
                        }
                    },
                    {
                        path: 'review-log',
                        component: reviewLog,
                        meta: {
                            title: '审计日志查询'
                        }
                    }
                ]
            }, {
                path: 'org-management',
                meta: {
                    title: '机构管理'
                },
                component: View,
                children: [
                    {
                        path: 'org-manage',
                        component: orgManage,
                        meta: {
                            title: '平台机构管理'
                        }
                    },
                    {
                        path: 'org-manage-read',
                        component: orgManage,
                        meta: {
                            title: '机构管理列表'
                        }
                    },
                    {
                        path: 'org-manage-bate',
                        component: orgManagBate,
                        meta: {
                            title: '医疗机构管理'
                        }
                    },
                    {
                        path: '/purview/org-management/import-preview',
                        component: orgManagImportPreview,
                        meta: {
                            title: '预览导入医疗机构数据',
                            display: 'none',
                            noMenu: true
                        }
                    },
                    {
                        path: 'org-manage-unit',
                        component: orgManagUnit,
                        meta: {
                            title: '管理单位管理'
                        }
                    },
                    {
                        path: '/purview/org-management/import-preview-unit',
                        component: orgManagImportPreviewUnit,
                        meta: {
                            title: '预览导入单位数据',
                            display: 'none',
                            noMenu: true
                        }
                    },
                    {
                        path: 'org-manage-enterprise',
                        component: orgManagEnterprise,
                        meta: {
                            title: '企业单位管理'
                        }
                    }
                    

                ]
            }, {
                path: 'app-manage',
                meta: {
                    title: '业务系统配置'
                },
                component: View,
                children: [
                    {
                        path: 'index',
                        component: () => import('@/pages/purview/app-manage/index'),
                        meta: {
                            title: '业务系统配置'
                        }
                    }

                ]
            },
            {
                path: 'area-manage',
                meta: {
                    title: '片区管理配置'
                },
                component: View,
                children: [
                    {
                        path: 'index',
                        component: () => import('@/pages/purview/area-manage/config'),
                        meta: {
                            title: '片区管理配置'
                        }
                    }

                ]
            },
            {
                path: 'message-management',
                meta: {
                    title: '消息公告管理'
                },
                component: View,
                children: [
                    {
                        path: 'message-config',
                        component: () => import('@/pages/purview/message-management/message-config'),
                        meta: {
                            title: '消息公告配置'
                        }
                    },
                    {
                        path: 'message-index',
                        component: () => import('@/pages/purview/message-management/message-index'),
                        meta: {
                            title: '消息公告管理'
                        }
                    }

                ]
            },
            {
                path: 'prefecture-audit',
                meta: {
                    title: '专区权限申请审核'
                },
                component: View,
                children: [
                    {
                        path: 'medical',
                        component: () => import('@/pages/purview/prefecture-audit/medical'),
                        meta: {
                            title: '专区权限申请审核-医保局'
                        }
                    },
                    {
                        path: 'operation',
                        component: () => import('@/pages/purview/prefecture-audit/operation'),
                        meta: {
                            title: '专区权限申请审核-运营端'
                        }
                    }

                ]
            }
        ]
    },
    {
        path: 'message',
        component: MessageList,
        meta: {
            title: '消息'
            // display: 'none'
            // noTag: true
        }
    },
    {
        path: '/personal-center',
        component: personalCenter,
        mate: {
            title: '个人中心'
        }
    }
];
