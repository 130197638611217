/*
 * @Author: zhujial
 * @Date: 2023-12-11 17:18:50
 * @Last Modified by: zhujial
 * @Last Modified time: 2024-04-03 16:49:05
 * @Description: 数据平台
 */
import View from '@/components/view';

export default [
    {
        path: '/data-plat/manage',
        component: View,
        meta: {
            title: '数据平台'
            // noTag: true
        },
        children: [
            {
                path: 'medicinal-data',
                component: View,
                meta: {
                    title: '药材数据'
                },
                children: [
                    {
                        path: 'market-price',
                        component: () => import(/* webpackChunkName: "marketPrice" */ '@/pages/data-plat/medicinal-data/market-price'),
                        meta: {
                            title: '中药材市场价格'
                        }
                    },
                    {
                        path: 'origin-price',
                        component: () => import(/* webpackChunkName: "originPrice" */ '@/pages/data-plat/medicinal-data/origin-price'),
                        meta: {
                            title: '中药材产地价格'
                        }
                    },
                    {
                        path: 'price-maintain',
                        component: () => import(/* webpackChunkName: "PriceMaintain" */ '@/pages/data-plat/medicinal-data/price-maintain/index'),
                        meta: {
                            title: '中药材价格维护'
                        }
                    },
                    {
                        path: 'price-maintain/detail',
                        component: () => import(/* webpackChunkName: "PriceMaintainDetail" */ '@/pages/data-plat/medicinal-data/price-maintain/price-detail'),
                        meta: {
                            title: '中药材价格维护',
                            display: 'none'
                        }
                    }
                ]
            },
            {
                path: 'data-manage',
                component: View,
                meta: {
                    title: '药材数据'
                },
                children: [
                    {
                        path: 'catalog-manage',
                        component: () => import(/* webpackChunkName: "medicinalCatalog" */ '@/pages/data-plat/data-manage/catalog-manage'),
                        meta: {
                            title: '中药材市场价格'
                        }
                    }
                ]
            },
            {
                path: 'data-analysis',
                component: View,
                meta: {
                    title: '数据采集'
                },
                children: [
                    {
                        path: 'price-data-detail',
                        component: () => import(/* webpackChunkName: "price-analysis-detail" */ '@/pages/data-plat/data-analysis/price-data-detail'),
                        meta: {
                            title: '价格数据明细'
                        }
                    }
                ]
            }
        ]
    }
];
