/* eslint-disable max-len */
/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-08 09:47:23
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-12-07 13:51:s18
 * @Description: 全局版头部
 */
<template>
    <div class="hx-header">
        <a v-if="$route.query.itemId || $route.query.tenditmId || proName" class="project-back"
            @click="backOuter">{{btnBackPath.label? btnBackPath.label :'返回项目外'}}</a>
        <div v-if="header.show" class="header-top">
            <div class="hx-header_left">
                <img v-if="header.logo" :src="header.logo">
                <span v-if="webName" class="header-top_title">
                    {{ webName }}
                </span>
                <span v-if="proName">
                    项目名称：{{ proName }}
                </span>
            </div>
            <div class="hx-header_right">
                <!-- <span v-if="isSmReport" style='margin-right: 5px;'>
                    <a style="color: #fff;" href="https://www.udplat.com/content.html?id=103" target="_">帮助文档下载</a>
                管理单位QQ群：306080854    医疗机构QQ群：879867517
                </span> -->
                <div class="xq-header-list__item" v-if="domainInfo.hotLine">
                    <i class="hxaxure hx-tell icon" />
                    <span> {{ domainInfo.hotLine }}</span>
                </div>
                <!-- 德州用 -->
                <div v-if="downloadFilterList && downloadFilterList.length !== 0" class="book-info"
                    @click="bookVisible = true">
                    <i class="hxaxure">&#xe761;</i>
                    <span>
                        帮助中心
                    </span>
                </div>
                <!-- 德州用 end -->
                <!-- 消息 -->
                <div class="message-box" @click="handleFull">
                <img class="message-box-img" :src="header.full ? header.closeFullScreen : header.openFullScreen">
                <span class="message-box-span">{{header.full ? '退出全屏' : '全屏模式'}}</span>
                    <!-- <div class="message-main">
                            <el-badge :hidden='!!!message'
                                      :value='Math.min(message,99)'
                                      :class="message?'item shake':'item'">
                                <div class="user-message-box">
                                    <i class="hxaxure">&#xe762;
                                    </i>
                                </div>
                            </el-badge>
                        </div>
                        <div class="mb-box">
                            <el-tabs v-model="activeName"
                                     @tab-click="handleClick">
                                <el-tab-pane :label="`消息公告 (${messageLeftList.length})`"
                                             name="messageLeft">
                                    <div class="mbb-list">
                                        <div class="mbbl-item"
                                             v-for="item in messageLeftList"
                                             :data-read='item.readFlag'
                                             :key="item.index"
                                             @click="()=>clickMessage()">
                                            <div class="mi-top">
                                                <div class="mit-l"></div>
                                                <div class="mit-r">【{{ item.noticeSettingName }}】{{ item.title }}</div>
                                            </div>
                                            <div class="mi-bottom">{{ item.publishTime }}</div>
                                        </div>
                                    </div>
                                    <div class="mbb-btn"
                                         @click="()=>clickMessage()">查看全部</div>
                                </el-tab-pane>
                                <el-tab-pane :label="messageRight"
                                             name="messageRight">
                                    <div class="mbb-list">
                                        <div class="mbbl-item"
                                             v-for="item in messageRightList"
                                             :key="item.index"
                                             @click="()=>clickMessage('4')">
                                            <div class="mi-top">
                                                <div class="mit-l"></div>
                                                <div class="mit-r">{{ item.title }}</div>
                                            </div>
                                            <div class="mi-bottom">{{ item.publishTime }}</div>
                                        </div>
                                    </div>
                                    <div class="mbb-btn"
                                         @click="()=>clickMessage('4')">查看全部</div>
                                </el-tab-pane>
                            </el-tabs>
                        </div> -->
                </div>
                <!-- 消息 end-->

                <div class="user-box">
                    <img :src="header.user" alt="">
                    <div class="user-box_text">
                        <span class="user-box--big">
                            {{ userInfo.orgName }}
                        </span>
                        <span>
                            {{ userInfo.nickName }}
                        </span>
                    </div>
                    <div class="user-box_side">
                        <ul>
                            <li @click="handlePersonalCenter">
                                 个人中心
                                </li>
                            <li @click="handleLogout">
                                退出系统
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="head-right">
            <el-tabs type="card" editable :value="currentPath" class="tab-pane-box"
                @tab-click="(el)=>handleTab(el.$options.propsData.name)" @tab-remove="handlePageTagClose">
                <!-- <el-tab-pane label="首页"
                             name="index" /> -->
                <el-tab-pane v-for="tag in pageTagsData" :key="tag.path" :label="tag.label" :name="tag.path">
                    {{ tag.label }}{{ tag.path }}
                </el-tab-pane>
            </el-tabs>
           <!-- <a data-btn="btnHeader" :class="header.show?'el-icon-d-arrow-left':'el-icon-d-arrow-right'"
                @click="handleHeaderShow" /> --> 
        </div>
        <el-dialog title="帮助文档下载" :visible.sync="bookVisible" append-to-body width="500px">
            <ol class="book-list">
                <li v-for="(item,i) in downloadFilterList" :key='i'>
                    <span>{{ i+1 }}</span>
                    <a @click="download(item)">
                        {{item.name}}
                        <span>
                            <i class="hxaxure">
                                &#xe703;
                            </i>
                            下载
                        </span>
                    </a>
                    <!-- <a :download="item" :href="item.url">{{ item.name }}</a> -->
                </li>
            </ol>
        </el-dialog>
        <el-dialog title="基本资料" :visible.sync="showAccount" append-to-body width="30%">
            <div class="account-main">
                <bk-form ref="form" :form-config="formConfig" />
            </div>
        </el-dialog>

        <el-dialog title="修改密码" :visible.sync="showPassword" custom-class="password-dialog" append-to-body width="30%">
            <div class="account-main">
                <bk-form ref="form" :form-config="formConfigPwd" />
            </div>
        </el-dialog>
        <div v-if="isShowHeader" class="mask-header" @click="isShowHeader=false;" />
        <el-dialog width="710px" custom-class="global-msg-dialog" :visible="msgVisible"
            @close="msgVisible = false;globalMsgId=[];" append-to-body :close-on-click-modal="false">
            <template #title>
                <bk-title label="未读公告消息" />
            </template>
            <ul>
                <li class="global-msg-dialog__list">
                    <div class="global-msg-dialog__unread">未读公告<span class="global-msg-dialog__unreadicon">{{
                            messageGlobalPopupList.length }}</span>条</div>
                </li>
                <li v-for="item in computedMessageLeftList" @click="handleMsgClick(item)"
                    :class="{'global-msg-dialog__msg--active': +item.readFlag === 1}"
                    class="global-msg-dialog__list global-msg-dialog__msg">
                    <div class="global-msg-dialog__radio"></div>
                    <p class="global-msg-dialog__content">{{item.title}}</p>
                    <div class="global-msg-dialog__time">{{ item.publishTime }}</div>
                </li>
            </ul>
            <template #footer>
                <div class="global-msg-dialog__footer" @click="handleClickAllMsg">查看全部</div>
            </template>
        </el-dialog>
        <!-- 详情 -->
        <el-dialog v-if="infoVisible" :visible.sync="infoVisible" @close="handleMsgDetailClose" append-to-body
            class="global-msg-dialog__docs" width="900px">
            <div>
                <notice-info :has-header="false" :params="noticeInfo" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
// 通过用头部
import { mapActions, mapState, mapGetters } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import { userLoginout, checkoutUserPasswordStatus } from '@/api';
// import { isEmptyObj } from '@/utils/utils';
import { mallReg } from '@/utils/regular';
import { encrypt2, downLoader, downloadFile } from '@/utils/utils';
import { gotofromHost } from '@/utils/fetch';
import NoticeInfo from '../pages/notice-info';

export default {
    components: {
        NoticeInfo,
    },
    props: {
        showAccountInfo: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            globalMsgId: [], // 记录全局弹框已读的id
            infoVisible: false, // 控制查看消息详情弹框
            noticeInfo: {}, // 消息对象
            msgVisible: false, // 消息弹框
            activeName: 'messageLeft',
            messageLeft: '消息公告（0）',
            isSmReport:(location.hostname.indexOf('sm')||location.hostname.indexOf('plustest'))&&this.$route.path.startsWith('/report'),
            messageLeftList: [],
            messageRightList: [],
            messageGlobalPopupList: [], // 全局弹框未读消息
            proName: '', //项目内名称
            areaProject: '', // 帮助中心
            downloadList: {
                dz: [
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
                        name: '用户管理操作手册.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%8D%AF%E5%93%81%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84%EF%BC%89.pdf',
                        name: '德州市药品交易结算操作手册（医疗机构）.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%8D%AF%E5%93%81%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E7%94%9F%E4%BA%A7%E4%BC%81%E4%B8%9A%EF%BC%89.pdf',
                        name: '德州市药品交易结算操作手册（生产企业）.pdf',
                        loading: false,
                    },
                    // {
                    //     url:
                    //         '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%8D%AF%E5%93%81%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E7%AE%A1%E7%90%86%E5%8D%95%E4%BD%8D%EF%BC%89.pdf',
                    //     name: '德州市药品交易结算操作手册（管理单位）.pdf ',
                    //     loading: false
                    // },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%8D%AF%E5%93%81%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A%EF%BC%89.pdf',
                        name: '德州市药品交易结算操作手册（配送企业）.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E8%80%97%E6%9D%90%E4%B8%AD%E9%80%89%E7%BB%93%E6%9E%9C%E7%A1%AE%E8%AE%A4%E9%9B%86%E9%87%87%E7%B3%BB%E7%BB%9F%E4%BC%81%E4%B8%9A%E7%AB%AF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
                        name: '德州耗材中选结果确认集采系统企业端操作手册.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E8%80%97%E6%9D%90%E8%81%94%E9%87%87%E7%B3%BB%E7%BB%9F%E4%BC%81%E4%B8%9A%E7%AB%AF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%85%8D%E9%80%81%E7%82%B9%E9%80%89%EF%BC%89.pdf',
                        name: '耗材联采系统企业端操作手册（配送点选）.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84.pdf',
                        name: '德州耗材交易操作手册-医疗机构.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-%E7%94%9F%E4%BA%A7%E4%BC%81%E4%B8%9A.pdf',
                        name: '德州耗材交易操作手册-生产企业.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A.pdf',
                        name: '德州耗材交易操作手册-配送企业.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%80%97%E6%9D%90%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84%EF%BC%89.pdf',
                        name: '德州市耗材结算操作手册（医疗机构）.pdf',
                        loading: false,
                    },
                    {
                        url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E5%B8%82%E8%80%97%E6%9D%90%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A%EF%BC%89.pdf',
                        name: '德州市耗材结算操作手册（配送企业）.pdf',
                        loading: false,
                    },
                ],
                hebei: [
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E6%B2%B3%E5%8C%97%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E6%BC%94%E7%A4%BA%E5%8C%BB%E9%99%A2%E7%AB%AF.mp4',
                        name: '河北耗材交易演示医院端.mp4',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E6%B2%B3%E5%8C%97%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E6%BC%94%E7%A4%BA%E9%85%8D%E9%80%81%E7%AB%AF.mp4',
                        name: '河北耗材交易演示配送端.mp4',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E6%B2%B3%E5%8C%97%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84.docx',
                        name: '河北耗材交易结算系统操作手册-医疗机构.docx',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E6%B2%B3%E5%8C%97%E8%80%97%E6%9D%90%E4%BA%A4%E6%98%93%E7%BB%93%E7%AE%97%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A.docx',
                        name: '河北耗材交易结算系统操作手册-配送企业.docx',
                        loading: false,
                    },

                    // 衡水
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E8%A1%A1%E6%B0%B4%E4%B8%93%E5%8C%BA%E8%8D%AF%E5%93%81%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84%EF%BC%89.docx',
                        name: '衡水专区药品结算操作手册（医疗机构）.docx',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E8%A1%A1%E6%B0%B4%E4%B8%93%E5%8C%BA%E8%8D%AF%E5%93%81%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A%EF%BC%89.docx',
                        name: '衡水专区药品结算操作手册（配送企业）.docx',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A%E7%BB%93%E7%AE%97%E8%AE%B2%E8%A7%A31.mp4',
                        name: '衡水专区药品结算操作视频1（配送企业）.mp4',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E9%85%8D%E9%80%81%E4%BC%81%E4%B8%9A%E7%BB%93%E7%AE%97%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E8%AE%B2%E8%A7%A32.mp4',
                        name: '衡水专区药品结算操作视频2（配送企业）.mp4',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84%E7%BB%93%E7%AE%97%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E8%AE%B2%E8%A7%A3.mp4',
                        name: '衡水专区药品结算操作视频（医疗机构）.mp4',
                        loading: false,
                    },
                    // task 18443
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E8%A1%A1%E6%B0%B4%E4%B8%93%E5%8C%BA%E8%8D%AF%E5%93%81%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E5%8C%BB%E4%BF%9D%E5%B1%80%EF%BC%89.docx',
                        name: '衡水专区药品结算操作手册（医保局）.docx',
                        loading: false,
                    },
                    {
                        url: '//bucket-hebeihaocai.oss-cn-beijing.aliyuncs.com/%E8%A1%A1%E6%B0%B4%E4%BA%A4%E6%98%93/%E8%A1%A1%E6%B0%B4%E4%B8%93%E5%8C%BA%E8%8D%AF%E5%93%81%E7%BB%93%E7%AE%97%E6%93%8D%E4%BD%9C%E8%A7%86%E9%A2%91%EF%BC%88%E5%8C%BB%E4%BF%9D%E5%B1%80%EF%BC%89.mp4',
                        name: '衡水专区药品结算操作视频（医保局）.mp4',
                        loading: false,
                    },
                ],
                // hxmec: [
                //     {
                //         url: '//bucket-dezhou.oss-cn-hangzhou.aliyuncs.com/help/%E5%BE%B7%E5%B7%9E%E8%80%97%E6%9D%90%E4%B8%AD%E9%80%89%E7%BB%93%E6%9E%9C%E7%A1%AE%E8%AE%A4%E7%94%B3%E6%8A%A5%E7%B3%BB%E7%BB%9F%E4%BC%81%E4%B8%9A%E7%AB%AF%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf',
                //         name: '申报系统企业端操作手册.pdf',
                //         loading: false,
                //     },
                // ],
            },
            header: {
                full: 0,
                logo: '',
                user: require('@/images/user.png'),
                name: '',
                name1: '',
                show: true,
                closeFullScreen: require('./../images/header/closeFullScreen.png'),
                openFullScreen: require('./../images/header/openFullScreen.png')
            },
            icon: true, // 系统旁边图标
            showAccount: false,
            showPassword: false,
            isShowHeader: false,
            bookVisible: false,
            orgType: '',
            locked: false,
            // 用户信息
            formConfig: {
                http: {
                    url: '/upms/user/getInfoDefault',
                    method: 'get',
                },
                data: [
                    {
                        // title: {
                        //     label: '订单明细'
                        // },
                        data: [
                            {
                                name: 'username',
                                label: '用户账号',
                                type: 'input',
                                grid: 1,
                                readonly: true,
                            },
                            {
                                name: 'nickName',
                                label: '姓名',
                                type: 'input',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                            },
                            {
                                name: 'email',
                                label: '电子邮件',
                                type: 'input',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                                rules: [
                                    {
                                        pattern: mallReg,
                                        message: '请输入正确的电子邮件',
                                        trigger: 'blur',
                                    },
                                ],
                            },
                            {
                                name: 'phone',
                                label: '手机',
                                type: 'input',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                                rules: [
                                    {
                                        pattern: /^1[^0-2][0-9]{9}$/g,
                                        message: '请输入正确的手机号',
                                        trigger: 'blur',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                button: {
                    data: [
                        {
                            name: 'subClose',
                            label: '关闭',
                            callback: () => {
                                this.showAccount = false;
                            },
                        },
                        {
                            name: 'submitEdit',
                            label: '提交',
                            http: {
                                url: '/upms/user/modifyUserInfo',
                                method: 'post',
                                // onBefore: (params) => {
                                //     params.id = localStorage.getItem('userKey');
                                // },
                                onSuccess: (res) => {
                                    if (res.code === 0) {
                                        this.$message.success('操作成功!');
                                        this.fetchUserInfo();
                                        this.showAccount = false;
                                    } else {
                                        this.$message.error(res.msg);
                                    }
                                },
                            },
                            loadingSwitch: true,
                        },
                    ],
                },
            },
            formConfigPwd: {
                http: {
                    url: '/upms/user/getInfoDefault',
                    method: 'get',
                },
                data: [
                    {
                        slot: [
                            {
                                name: 'under-form',
                                html: `
                                    <div style="padding-left: 8px;color: red;font-size: 14px;line-height: 19px;">
                                        <span>格式说明</span>
                                        <ul>
                                            <li>1.字符长度8位以上</li>
                                            <li>2.包含大小写字母、数字、特殊字符至少2种进行组合</li>
                                        </ul>
                                    </div>
                                        `,
                            },
                        ],
                        data: [
                            {
                                name: 'oldPassword',
                                label: '旧密码',
                                type: 'password',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入旧密码',
                                        trigger: ['change', 'blur'],
                                    },
                                ],
                            },
                            {
                                name: 'newPassword',
                                label: '新密码',
                                type: 'password',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入新密码',
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const { oldPassword } = this.$refs.form.formParams;
                                            if (value === oldPassword) {
                                                callback(new Error('输入的密码和旧密码一致，请重新输入'));
                                            } else {
                                                callback();
                                            }
                                        },
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value.length < 8 || value.length > 20) {
                                                callback(new Error('密码长度至少8位，最多20位'));
                                            } else {
                                                callback();
                                            }
                                        },
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        pattern:
                                            /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/g,
                                        message: '包含大小写字母、数字、特殊字符至少2种进行组合',
                                        trigger: 'blur',
                                    },
                                ],
                            },
                            {
                                name: 'confirmPassword',
                                label: '确认密码',
                                type: 'password',
                                grid: 1,
                                extend: {
                                    clearable: true,
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入确认密码',
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const { newPassword } = this.$refs.form.formParams;
                                            if (value !== newPassword) {
                                                callback(new Error('确认密码和新密码不一致'));
                                            } else {
                                                callback();
                                            }
                                        },
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value.length < 8 || value.length > 20) {
                                                callback(new Error('密码长度至少8位，最多20位'));
                                            } else {
                                                callback();
                                            }
                                        },
                                        trigger: ['change', 'blur'],
                                    },
                                    {
                                        pattern:
                                            /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/g,
                                        message: '包含大小写字母、数字、特殊字符至少2种进行组合',
                                        trigger: 'blur',
                                    },
                                ],
                            },
                        ],
                    },
                ],
                button: {
                    data: [
                        {
                            name: 'subClose',
                            label: '关闭',
                            callback: () => {
                                this.showPassword = false;
                            },
                        },
                        {
                            name: 'submitEdit',
                            label: '提交',
                            http: {
                                url: '/upms/user/resetPassWord',
                                method: 'post',
                                onBefore: (params) => {
                                    const { oldPassword, newPassword, confirmPassword } = params;
                                    params.oldPassword = encrypt2(oldPassword);
                                    params.newPassword = encrypt2(newPassword);
                                    params.confirmPassword = encrypt2(confirmPassword);
                                    // params.id = localStorage.getItem('userKey');
                                    return params;
                                },
                                onSuccess: (res) => {
                                    if (res.code === 0) {
                                        this.$message.success('操作成功!');
                                        this.showPassword = false;
                                    } else {
                                        this.$message.error(res.msg);
                                    }
                                    this.formConfigPwd.button.data[0].visible = true;
                                },
                            },
                            loadingSwitch: true,
                        },
                    ],
                },
            },
            pageTagsData: [], // 页签
            breadcrumbData: [], // 面包屑
            currentMeunList: '', // 当前的菜单数据
            currentPath: '', // 当前地址
        };
    },
    watch: {
        $route(val) {
            this.setTopMeun(val);
            this.getNoticeNum();
        },
        messageLeftList(val) {
          const ifShowGlobalDialog = JSON.parse(localStorage.getItem('ifShowGlobalDialog'));
          // 经过过滤函数，只判断未读的消息
          if (val.filter(item => +item.readFlag === 0).length > 0 && ifShowGlobalDialog) {
            // 全局弹出之后置为false否则会返回弹出，除非经过portal路由，此设置在portal的mounted生命周期中。
            localStorage.setItem('ifShowGlobalDialog', false);
          }
        },
        /**
         * 更新当前实际菜单 （串页面校验）
         */
        applyMeunList() {
            this.getCurrentMeunList();
        },
        /**
         * 串页面校验
         */
        currentMeunList(val, old) {
            if (val === old) return;
            this.handleRoute(this.$route);
        },
        /**
         * 获得项目内名称
         */
        innerProName() {
            this.setProName();
        },
    },
    computed: {
        ...mapState({
            applyMeunList: 'applyMeunList',
            webName: 'webName',
            userInfo: 'userInfo',
            message: 'message',
            routeData: 'routeData',
            pageIndex: 'index',
            innerProName: 'innerProName',
            btnBackPath: 'btnBackPath',
            domainInfo:'domainInfo',
        }),
        ...mapGetters(['userInfo']),
        messageRight() {
            return `站内信（${this.message}）`;
        },
        computedMessageLeftList() {
          // const filterList = this.messageLeftList.filter(item => {
          //   // globalMsgId数组记录当前用户看的信息，在当前弹框不过滤，一旦用户点击关闭后清除这个globalMsgId数组。
          //   if (+item.readFlag === 1 && this.globalMsgId.includes(item.noticeInfoId)) {
          //     return true;
          //   }
          //   return +item.readFlag === 0;
          // });
          if (this.messageGlobalPopupList.length > 0) {
            this.msgVisible = true;
          }
          if (this.messageGlobalPopupList.length <= 4) {
              return uniqBy(this.messageGlobalPopupList, 'noticeInfoId');
          } else {
              return uniqBy(this.messageGlobalPopupList.slice(0, 5), 'noticeInfoId');
          }

        },
        /**
         * 全部路由信息
         */
        navData() {
            const obj = {};
            for (let i = 0; i < this.routeData.length; i += 1) {
                const item = this.routeData[i];
                const { path } = item;
                if (!obj[path]) {
                    obj[path] = this.getRouteInfo([item]);
                }
            }
            return obj;
        },
        downloadFilterList() {
            return this.downloadList[this.areaProject] || [];
        },
    },
    created() {
        this.initHeaderStatus();
        this.valitePassword();
        this.setProName();
        this.getNoticeNum();
        // 设置项目归属
        const isDZ = window.location.host.indexOf('dz') > -1 || window.location.host.indexOf('dezhou') > -1;
        const isHebei = window.location.host.indexOf('hebei') > -1;
        const isHx = window.location.href.indexOf('aupqc') > -1;
        if (isDZ) {
            this.areaProject = 'dz';
        } else if (isHebei) {
            this.areaProject = 'hebei';
        } else if (isHx) {
            this.areaProject = 'hxmec';
        }
    },
    mounted() {
        // 在组件加载后添加全屏事件的监听器
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        let info = localStorage.getItem('userInfo');
        if (info) {
            info = JSON.parse(info);
            this.header.name = info.orgName;
            this.header.name1 = info.nickName;
        }
        // 每次刷新的时候去获得用户信息
        this.$nextTick(() => {
            this.fetchUserInfo().then(() => {
                this.fetchUserMessageCount();
            });
        });
        // 获得当前主路由 （串页面使用）
        this.getCurrentMeunList();
        this.setTopMeun(this.$route);
        // 获取全局弹框未读消息
        this.getGlobalPopupMsg();
    },
    destroyed() {
        // 在组件销毁前移除全屏事件的监听器
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    },
    methods: {
        ...mapActions(['fetchUserInfo', 'fetchApplyMenu', 'fetchUserMessageCount']),
        // 全局消息弹框点击查看全部消息
        handleClickAllMsg() {
            this.msgVisible = false;
            this.clickMessage();
        },
        // 全局消息弹框消息列表点击事件
        handleMsgClick(item) {
            this.noticeInfo = item;
            this.globalMsgId.push(item.noticeInfoId);
            console.log(item, 'item');
            // if (item.openType === 'OPEN_POPUP') {
              this.infoVisible = true;
            // }
        },
        // 消息详情页点击事件
        handleMsgDetailClose() {
            this.getNoticeList();
        },
      /**
         * 获得未读消息数
         */
        getNoticeNum() {
            this.$fetch({
                url: '/upms/msgNoticeInfo/queryNoticeMsgNum',
                params: {
                    readFlag: false,
                    systemId: localStorage.getItem('system_id'),
                },
            }).then((res) => {
                if (res) {
                    this.messageLeft = `消息公告（${res.data}）`;
                }
            });
            this.getNoticeList();
            this.getMessageList();
        },
        /**
         * 获得未读的消息
         */
        getNoticeList() {
            this.$fetch({
                url: '/upms/msgNoticeInfo/userMsgPage',
                params: {
                    readFlag: false,
                    systemId: localStorage.getItem('system_id'),
                    pageSize: 1000,
                },
            }).then((res) => {
                if (res) {
                    this.messageLeftList = res.data.records;
                }
            });
        },
        // 全局弹框未读消息
        getGlobalPopupMsg() {
          this.$fetch({
            url: '/upms/msgNoticeInfo/userMsgPage',
            params: {
              readFlag: false,
              systemId: localStorage.getItem('system_id'),
              pageSize: 1000,
            },
          }).then((res) => {
            if (res) {
              this.messageGlobalPopupList = res.data.records;
            }
          });
        },
        /**
         * 获得未读的站内信
         */
        getMessageList() {
            this.$fetch({
                url: '/upms/sysMessage/page',
                params: {
                    readFlag: false,
                    // pageSize:10
                },
            }).then((res) => {
                if (res) {
                    this.messageRightList = res.data.records;
                }
            });
        },

        handleClick(tab, event) {
            console.log(tab, event);
        },
        /**
         * 项目内名称
         * 目前项目内外是用window.href 打开，因此使用缓存处理
         */
        setProName() {
            console.log(this.innerProName);
            this.proName = this.innerProName || localStorage.getItem('innerProName');
        },
        // 初始化页面状态
        initHeaderStatus() {
            document.body.dataset.header = true;
        },
        // 页头隐藏
        handleHeaderShow() {
            this.header.show = !this.header.show;
            document.body.dataset.header = this.header.show;
        },
        valitePassword() {
            checkoutUserPasswordStatus().then((res) => {
                if (res.code === 0 && !res.data) {
                    this.formConfigPwd.button.data[0].visible = false;
                    this.showPassword = true;
                    this.formConfigPwd.data[0].slot.push({
                        name: 'under-title',
                        html: '<span style="padding-left: 8px;color: red;">您已经3个月未修改密码，请修改</span>',
                    });
                }
            });
        },
        /**
         * 全屏控制
         */
        handleFull() {
            if (this.header.full) {
                this.exitFullscreen();
            } else {
                this.requestFullscreen();
            }
        },
        /**
         * 进入全屏
         */
        requestFullscreen() {
            this.header.full = 1;
            this.$emit('changeMenu', false)
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            }
        },
        /**
         * 退出全屏
         */
        exitFullscreen() {
            this.header.full = 0;
            this.$emit('changeMenu', true)
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        },
        /**
         * 监听退出全屏事件
         */
        handleFullscreenChange() {
            // 在退出全屏时执行的操作
            if (!document.fullscreenElement) {
                // 退出全屏隐藏图标
                this.header.full = 0;
                this.$emit('changeMenu', true)
            }
        },
        /**
         * tag 切换
         */
        handleTab(path, push = 'push') {
            if (path === 'index') {
                // 跳转往首页
                let index = window.location.pathname.split('/')[1];
                if (this.pageIndex) {
                    index = this.pageIndex.match(/\//g) ? this.pageIndex : `/${this.pageIndex}`;
                }
                this.$router[push](index);
                return;
            }
            const data = this.pageTagsData.find((item) => item.path === path);
            if (data) {
                const { fullPath } = data.arr[data.arr.length - 1];
                if (fullPath !== this.$route.fullPath) this.$router[push](fullPath);
            }
        },
        /**
         * 获得所有路由信息
         * @param {Array} params 所有路由信息
         * @param {Object} data 生成数据集合
         * @param {String} parentPath 父级路由
         */
        getRouteInfo(params, data = {}, parentPath) {
            if (Array.isArray(params)) {
                params.forEach((item) => {
                    const { path, meta, children } = item;
                    // 获得全地址
                    let currentPath = parentPath || '';
                    if (path && path.match(/^\//)) {
                        // 带根级
                        currentPath = path;
                    } else if (path && !path.match(/^\//)) {
                        currentPath += '/' + path;
                    }
                    // 非绝对地址
                    currentPath = currentPath.replace(/\/\//g, '/');
                    // 保存有效路由，带children为父级无效路由
                    if (!children) {
                        data[currentPath] = meta;
                    }
                    if (children && children.length) {
                        this.getRouteInfo(children, data, currentPath);
                    }
                });
            }

            return data;
        },
        /**
         * 获取上级的地址
         * @param {Object} target 路由信息
         * @param {String} path 路由
         * @param {Array} data 数组
         */
        getRouteInfoParentPath(target, path, data = []) {
            const item = target[path];
            if (!item) return data;
            const pathInfo = path.split('/');

            let parentPath = '';
            // 向上探索父级信息
            for (let i = 0; i < pathInfo.length - 1; i += 1) {
                const name = pathInfo[i];
                if (name) parentPath += `/${name}`;
            }
            if (parentPath) {
                // 是否存在父级
                const parent = target[parentPath] || target[parentPath + '/'];
                if (!parent) return data;
                data.unshift({
                    label: parent.title,
                    fullpath: parentPath,
                    path: parentPath,
                });
                this.getRouteInfoParentPath(target, parentPath, data);
            }

            return data;
        },
        /**
         * 获得当前菜单拥有的主路由 （串页面bug）
         */
        getCurrentMeunList() {
            if (this.applyMeunList) {
                this.currentMeunList = this.getRouteInfo(this.applyMeunList);
            }
        },
        /** *
         * 处理用户的串页面行为
         *  */
        handleRoute(route) {
            const { path } = route;
            // 路由信息
            let project = path.split('/');
            if (project.length) {
                project = `/${project[1]}`;
            }
            // 获得路由集
            const projectLabels = this.navData[project];

            if (!projectLabels) {
                return false;
            }
            // 获得当前路由信息
            let info = '';
            if (projectLabels[path]) {
                info = {
                    projectLabels, // 当前路由信息集
                    info: projectLabels[path],
                };
            }
            if (!info) return false;
            /**
             * 串页面判断，只检查主页，内容跳过
             */
            const mate = info.info;
            if (mate.display === 'none' || mate.noTag) {
                return info;
            }

            // 串页面判断
            if (!this.applyMeunList.length || !this.currentMeunList.length) return info;
            // 是否在当前主页菜单中
            const paths = Object.keys(this.currentMeunList);
            const isHas = paths.indexOf(path) > -1;
            if (!isHas) {
                // 处理菜单接口过慢,渲染以完毕的情况
                // 删除当前打开的页签
                const index = this.pageTagsData.findIndex((item) => item.path === path);
                if (index > -1) this.pageTagsData.splice(index, 1);
                // 执行跳出
                this.$router.replace({ path: '/purview/404' });
            }

            return info;
        },
        /**
         * 设置顶部导航
         */
        setTopMeun(route) {
            let isHas = false;
            const { path, fullPath, query } = route;
            // 获得路由信息
            const data = this.handleRoute(route);
            if (!data) {
                // 不需要tag
                this.currentPath = fullPath;
                return;
            }
            // projectLabels 全部路由信息
            const { info, projectLabels } = data;
            /**
             * 当前路由信息
             */
            const current = {
                path,
                label: info.title || '页签',
                fullPath,
            };
            /**
             * 如果是项目内路由，处理项目外菜单
             */
            if (query.itemId) {
                this.cleanOuter();
            }
            // 检查路由是否存在页签中
            for (let i = 0; i < this.pageTagsData.length; i += 1) {
                // 单个页签
                const item = this.pageTagsData[i];
                let booleanStr = false;
                // 如果是数据分析的我关注的看板的菜单
                if (path === '/analysis/my-view/view-main') {
                    booleanStr = fullPath.includes(item.arr[0].fullPath);
                } else if (path === item.arr[0].path) {
                    // 是否存在一样的路径
                    booleanStr = true;
                } else {
                    // 是否存在父级路径
                    booleanStr = path.includes(item.arr[0].path + '/');
                }
                // 比对是否已存在
                if (booleanStr) {
                    // 生成新面包屑
                    const newArr = [];
                    // 原面包屑
                    const { arr } = item;
                    // 子级别菜单处理
                    for (let c = 0; c < arr.length; c += 1) {
                        const cell = arr[c];
                        // 创建新的面包屑
                        newArr.push(cell);
                        if (cell.path === path) {
                            // 已存在执行替换
                            cell.fullPath = fullPath;
                            break;
                        } else if (c === arr.length - 1) {
                            // 不存在时插入
                            newArr.push(current);
                        }
                    }
                    item.arr = newArr;
                    // 页签当前焦点
                    item.path = fullPath;
                    isHas = i;
                    this.currentPath = fullPath;
                    // 设置当前的面包屑
                    this.breadcrumbData = newArr;
                    break;
                }
            }
            // 创建新页签
            if (isHas === false) {
                const parents = this.getRouteInfoParentPath(projectLabels, path);
                let { title } = info;
                if (parents.length && path !== '/analysis/my-view/view-main') {
                    // 获取父级的名称
                    title = parents[0].label;
                }
                const arr = [...parents, current];
                if (path.indexOf('/analysis/my-view') !== -1) {
                    setTimeout(() => {
                        this.pageTagsData.push({
                            label: info.title,
                            path: fullPath,
                            arr,
                        });
                    }, 300);
                } else {
                    this.pageTagsData.push({
                        label: title,
                        path: fullPath,
                        arr,
                    });
                }

                this.currentPath = fullPath; // 设置当前地址
                // 设置当前的面包屑
                this.breadcrumbData = arr;
                // 当前页签
                isHas = this.pageTagsData.length - 1;
            }
            this.$emit('route', this);
        },

        // 点击消息
        clickMessage(num = '0') {
            this.$router.push({
                path: '/message',
                query: {
                    mode: num,
                },
            });
        },
        // 退出登陆
        handleLogout() {
            this.isShowHeader = false;
            if (this.locked) return;
            this.locked = true;
            this.$store.commit('CLEAR_USERINFO');
            userLoginout()
                .then(() => {
                    this.locked = false;
                    localStorage.setItem('token', ''); // 清除token
                    // 处理外链型系统
                    const auth = localStorage.getItem('authUrl');
                    if (auth) {
                        localStorage.removeItem('authUrl');
                        window.location.href = auth;
                    } else {
                        gotofromHost('?token=null', this);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.locked = false;
                    localStorage.setItem('token', ''); // 清除token
                    // 处理外链型系统
                    const auth = localStorage.getItem('authUrl');
                    if (auth) {
                        localStorage.removeItem('authUrl');
                        window.location.href = auth;
                    } else {
                        gotofromHost('?token=null', this);
                    }
                    /* eslint no-console: 'off' */
                });
        },
        /**
         * 删除页签
         * param {Object} tagObj 被删除页签的数据对象
         */
        handlePageTagClose(tagPath) {
            const { fullPath, path: currentPath } = this.$route;
            const tagIndex = this.pageTagsData.findIndex((tag) => tag.path === tagPath); // 获得删除的对象
            if (tagIndex !== -1) {
                // 如果是当前页面跳转
                if (currentPath === tagPath || fullPath === tagPath) {
                    let to = tagIndex; // 前往的页签
                    const total = this.pageTagsData.length; // 页签总数
                    // 要调整的地址
                    let toRouter = '/';
                    if (tagIndex === 0 && total >= 2) {
                        // 当为第一个时
                        to += 1;
                        toRouter = this.pageTagsData[to];
                    } else if (total > 1) {
                        // 当有多个，并且非第一个时
                        to -= 1;
                        toRouter = this.pageTagsData[to];
                    } else if (total === 1) {
                        // 当只有一个时 不能关闭
                        toRouter = { path: 'index' };
                    }
                    this.handleTab(toRouter.path, 'replace');
                }
                if (this.pageTagsData.length === 1 && this.pageIndex && this.pageIndex === this.pageTagsData[0].path) {
                    // 只有一个标签，并且有配置默认路由=最后一个标签的时候不关闭和跳转
                    return;
                }
                this.pageTagsData = this.pageTagsData.filter((tab) => tab.path !== tagPath);
            }
        },
        /**
         * 退出项目内
         */
        backOuter() {
            const { path, query } = this.$route;
            const route = '/' + path.split('/')[1];
            this.$store.commit('CLEAN_INNER_PRO_NAME'); // 清除项目名称
            // 目前路由守卫添加了有项目id就会自己添加上的逻辑导致只能通过页面跳转清除项目id
            // this.$router.push({
            //     path: `/${route[1]}`
            // });
            // this.resetTag();
            // 全量版的gCode逻辑
            const { gCode } = query;
            let cPath = this.btnBackPath.path || route;
            if (gCode && cPath.indexOf('gCode') === -1) {
                if (cPath.indexOf('&') > -1 || cPath.indexOf('?') > -1) {
                    cPath += `&gCode=${gCode}`;
                } else {
                    cPath += `?gCode=${gCode}`;
                }
            }
            // 支持设置一个项目外路径
            window.location.href = cPath;
        },
        /**
         * 清除项目外
         */
        cleanOuter() {
            const arr = [];
            for (let i = 0; i < this.pageTagsData.length; i += 1) {
                const item = this.pageTagsData[i];
                if (item.path.indexOf('proj-out') === -1) {
                    arr.push(item);
                }
            }
            this.pageTagsData = arr;
        },
        download(item) {
            if (!item.loading) {
                item.loading = true;
                this.$message.warning('请稍等文件解析中！');
                if (item.url.endsWith('pdf') || item.url.endsWith('mp4')) {
                    downLoader(item.url, item.name, () => {
                        item.loading = false;
                    });
                } else {
                    downloadFile(item.url);
                }
            } else {
                this.$message.warning('请稍等文件解析中！');
            }
        },
        /**
         * 个人中心
         */
        handlePersonalCenter(){
            this.$router.push({
                path:'/personal-center'
            })
        }
    },
};
</script>

<style lang="less" scoped>
// new
.hxaxure {
    font-size: 28px;
}
.header-top {
    height: 56px;
    background: @--color-primary;
    color: @--color-white;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    .hx-header_left {
        flex: 1 1 auto;
        display: inline-flex;
        align-items: center;
    }
    .hx-header_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: none;
        min-width: 481px;
        height: 100%;
        .xq-header-list__item {
            font-size: 16px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            .icon {
                font-size: 28px;
                margin-right: 5px;
            }
            span {
                white-space: nowrap;
            }
        }
    }
    .message-box {
        display: inline-flex;
        flex: none;
        justify-content: space-around;
        align-items: center;
        width: 110px;
        padding: 0 2px;
        height: 100%;
        font-size: 25px;
        cursor: pointer;
        position: relative;
        /deep/ .el-badge__content {
            border: 0 none;
        }
        &:hover {
            transition: 0.5s;
            background: @--background-color-menu-base;
            .mb-box {
                display: block;
            }
        }
        .mb-box {
            display: none;
            position: absolute;
            z-index: 9999;
            left: -150px;
            top: 56px;
            width: 360px;
            max-height: 472px;
            opacity: 1;
            background-color: white;
            box-shadow: 0 4px 20px rgba(154, 179, 229, 0.262357);
            .mbb-list {
                max-height: 350px;
                overflow-y: scroll;
                .mbbl-item {
                    height: 70px;
                    opacity: 1;
                    background: rgba(216, 216, 216, 0);
                    border-bottom: 1px solid #f0f2f5;
                    &:hover {
                        background-color: #f5f8ff;
                    }
                    &[data-read] {
                        opacity: 0.7;
                    }
                    .mi-top {
                        padding-top: 16px;
                        display: flex;
                        align-items: center;
                        .mit-l {
                            margin-left: 16px;
                            width: 6px;
                            height: 6px;
                            background-color: #1b65b9;
                            border-radius: 2px;
                        }
                        .mit-r {
                            width: 306px;
                            height: 16px;
                            overflow: hidden;
                            margin-left: 8px;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            color: #303133;
                            line-height: 14px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    .mi-bottom {
                        padding-top: 12px;
                        padding-left: 20px;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: left;
                        color: #606266;
                        line-height: 12px;
                    }
                }
            }
            .mbb-btn {
                margin: 16px auto;
                width: 109px;
                height: 36px;
                opacity: 1;
                background: #1b65b9;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                line-height: 36px;
            }
        }
        .message-box-img {
            width: 28px;
            height: 28px;
        }
        .message-box-span {
            font-size: 16px;
        }
    }
    .header-top_title {
        font-size: 26px;
        line-height: 56px;
        display: inline-block;
        width: 236px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .user-box {
        max-width: 435px;
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 100%;
        padding-left: 10px;
        img {
            border-radius: 50%;
            height: 40px;
            min-width: 40px;
            overflow: hidden;
            margin-right: 10px;
        }
        &:hover {
            transition: 0.5s;
            background: @--background-color-menu-base;
            .user-box_side {
                display: block;
            }
        }
    }
    .user-box_text {
        flex: 1;
        min-width: 0;
        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            font-size: 12px;
            &.user-box--big {
                font-size: 18px;
                height: 20px;
                margin-bottom: 2px;
            }
        }
    }
    .user-box_side {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 11;
        width: 160px;
        padding-top: 10px;
        ul {
            background: @--color-white;
            box-shadow: @--box-shadow-light;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 5px;
                margin: auto;
                display: block;
                width: 0;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-bottom: 5px solid @--color-white;
            }
        }
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 40px;
            cursor: pointer;
            color: @--color-text-regular;
            &:hover {
                background: @--background-color-base;
            }
        }
    }
}
.book-list {
    height: 400px;
    overflow: auto;
    li {
        display: flex;
        margin-bottom: 10px;
        a {
            padding-left: 5px;
            display: block;
            width: 100%;
            cursor: pointer;
        }
        .hxaxure {
            margin-left: 20px;
            font-size: 14px;
        }
    }
}
.hx-header {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    color: @--color-text-regular;
    font-size: 14px;
    // background-color: @--color-white;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    * {
        box-sizing: border-box;
    }
    .project-back {
        position: fixed;
        right: 0;
        top: 108px;
        display: block;
        width: 22px;
        line-height: 14px;
        border-radius: 6px 0 0 6px;
        padding: 6px 0 6px 4px;
        box-shadow: @--box-shadow-light;
        background: @--background-color-menu-base;
        color: @--color-white;
        cursor: pointer;
    }
    .logo-wrapper {
        float: left;
        width: 256px;
        height: 64px;
        // background-color: @--color-primary;
        color: @--color-white;
        text-align: center;
        img {
            width: 37px;
            height: 32px;
        }
        .logo-icon {
            font-size: 18px;
            float: right;
            line-height: 64px;
            padding-right: 15px;
            cursor: pointer;
        }
    }
    .book-info {
        width: 100px;
        flex: none;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    // 用户操作 导航
    .head-right {
        /deep/ .el-tabs.el-tabs--card {
            width: calc(100% - 38px);
            > .el-tabs__header .el-tabs__nav {
                border: 0 none;
            }
            .el-tabs__item {
                height: 26px;
                line-height: 26px;
                padding: 0 10px;
                margin: 0 5px;
                background-color: @--background-color-tag;
                border: @--border-base;
                border-radius: 5px;
                color: @--color-info;
                &.is-closable .el-icon-close {
                    width: 14px;
                }
                // 首页
                // &:first-of-type {
                //     border: 0 none;
                //     background: none;
                //     color: @--background-color-menu-base;
                //     &.is-closable .el-icon-close {
                //         display: none;
                //     }
                // }
                &.is-active {
                    background: @--background-color-active-tag;
                    color: @--side-color;
                    border-color: @--background-color-active-tag;
                }
            }
            > .el-tabs__header {
                border: 0 none;
            }
            .el-tabs__nav-next,
            .el-tabs__nav-prev {
                line-height: 26px;
            }
            .el-tabs__content {
                display: none;
            }
            .el-tabs__new-tab {
                display: none;
            }
        }
        a[data-btn='btnHeader'] {
            cursor: pointer;
            float: right;
            font-size: 16px;
            width: 28px;
            height: 28px;
            display: block;
            text-align: center;
            line-height: 28px;
            border-radius: 50%;
            background: @--background-color-light;
            transform: rotate(90deg);
        }
    }
    .page-tag-wrapper {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 100px);
        overflow: hidden;
        white-space: nowrap;
        a.tag-home {
            padding-left: 10px;
            font-size: 14px;
            vertical-align: middle;
        }
        .tag-btn {
            font-size: 14px;
            vertical-align: middle;
            cursor: pointer;
            &:first-of-type {
                padding-left: 15px;
            }
            &:last-of-type {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .tag-back {
            font-size: 14px;
            padding-left: 15px;
            vertical-align: middle;
            cursor: pointer;
        }
        .page-tag-td {
            display: inline-block;
            width: 150px;
            overflow: hidden;
            padding-left: 16px;
            vertical-align: middle;
        }
        /deep/ .el-tag {
            display: table;
            padding: 5px 16px;
            height: 24px;
            max-width: 100%;
            line-height: 1.3;
            background-color: @--background-color-tag;
            border: @--border-base;
            border-radius: 5px;
            color: @--color-info;
            box-sizing: border-box;
            > * {
                display: table-cell;
            }
            .el-icon-close {
                top: 1px;
                color: @--color-info;
            }
            &:hover,
            &.is-active {
                transition: 0.5s;
                background-color: @--color-primary;
                color: @--color-white;
                cursor: pointer;

                .el-icon-close {
                    top: 1px;
                    color: @--color-white;
                }
            }
        }
        .tag-label {
            line-height: 1.4;
            display: inline-block;
        }
    }
    a[data-btn='btnHeader'] {
        cursor: pointer;
        float: right;
        font-size: 16px;
        width: 28px;
        height: 28px;
        display: block;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        background: @--background-color-light;
        transform: rotate(90deg);
    }
    .user-right-box {
        vertical-align: top;
        display: inline-block;
        width: 280px;
        height: 64px;
        overflow: hidden;
        font-size: 0;
        .messageBox {
            float: left;
            height: 64px;
            cursor: pointer;
            .message-main {
                margin-top: 12px;
                padding-right: 20px;
                height: 40px;
                line-height: 40px;
                .user-message-box {
                    color: @--color-text-regular;
                    font-size: 14px;
                }
            }
        }
        .user-name {
            color: @--color-primary;
            font-size: 14px;
            text-decoration-line: none;
            position: relative;
            cursor: pointer;
        }
        .tip-txt {
            color: @--color-text-regular;
            font-weight: 400;
            font-size: 14px;

            &::before {
                content: '';
                display: inline-block;
                margin: -3px 10px 0 0;
                width: 20px;
                height: 20px;
                background-image: url('@{--image-path-base}/avatar.png');
                background-repeat: no-repeat;
                background-size: 100%;
                vertical-align: middle;
            }
        }
        .vertical-line {
            margin: 0 13px 0 17px;
            color: @--border-color-light;
            font-size: 14px;
        }
        .user-exit {
            margin: 0 16px 0 0;
            cursor: pointer;
            font-size: 14px;

            &::before {
                content: '';
                display: inline-block;
                margin: -2px 6px 0 0;
                width: 20px;
                height: 20px;
                background-image: url('@{--image-path-base}/logout.png');
                background-repeat: no-repeat;
                background-size: 100%;
                vertical-align: middle;
            }
        }
    }
}
.mask-header {
    position: fixed;
    z-index: 998;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.tipMain {
    position: fixed;
    cursor: initial;
    right: 40px;
    top: 65px;
    padding: 0;
    width: 260px;
    margin-left: -130px;
    background-color: @--color-white;
    z-index: 999;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .headerTop {
        position: relative;
        background-color: rgb(240, 242, 245);
        padding: 0 15px;
        text-align: center;
        .log-out {
            position: absolute;
            cursor: pointer;
            right: 15px;
            top: 15px;
            font-size: 14px;
            color: rgba(96, 98, 102);
            line-height: 16px;
            &::before {
                content: '';
                display: inline-block;
                margin: -3px 0 0 0;
                width: 14px;
                height: 14px;
                background-image: url('@{--image-path-base}/log-out.png');
                background-repeat: no-repeat;
                background-size: 100%;
                vertical-align: middle;
            }
        }
        .headerImg {
            margin: 0 auto;
            width: 64px;
            height: 64px;
            background-image: url('@{--image-path-base}/tip_header.png');
            background-size: 100% 100%;
        }
        .userName {
            margin-top: 10px;
            color: #303133;
            font-size: 16px;
            line-height: 18px;
        }
        .hosp-name {
            margin-top: 5px;
            font-size: 12px;
            color: rgba(96, 98, 102, 1);
            line-height: 14px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .bottom-main {
        background-color: white;
        padding: 15px;
        .login-time {
            text-align: left;
            font-size: 14px;
            color: rgba(96, 98, 102);
            line-height: 16px;
        }
        .btn-main {
            margin-top: 10px;
            overflow: hidden;
            .btn-left {
                float: left;
                font-size: 14px;
                color: #606266;
                width: 88px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
                background-color: white;
                border: 1px solid gainsboro;
            }
            .btn-right {
                float: right;
                font-size: 14px;
                color: #606266;
                width: 88px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                cursor: pointer;
                background-color: white;
                border: 1px solid gainsboro;
            }
        }
    }
}
</style>

<style lang="less">
.password-dialog {
    .el-dialog__body {
        padding-top: 10px;
    }
}
</style>
